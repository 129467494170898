import React, { useRef, useEffect, useContext } from 'react';
import styles from './detail-block-small-image.module.scss';
import { gsap } from 'gsap';
import { textLinesAnimation } from '../../../util/transitions/animationUtils';
import scrollTransition from '../../../util/transitions/scrollTransition';
import Icon from '../Icon';
import ModalContext, { modalNames } from '../../../context/modal/ModalContext';
import { useTranslation } from 'react-i18next';

const DetailBlockSmallImage = ({ data }) => {
  const { t } = useTranslation('global');
  const modal = useContext(ModalContext);
  const wrapperRef = useRef(null);

  useEffect(() => {
    let scrollTrigger;

    gsap.set(wrapperRef.current, textLinesAnimation.from);

    setTimeout(() => {
      if (!wrapperRef.current) {
        return;
      }

      scrollTrigger = scrollTransition(
        wrapperRef.current,
        gsap.fromTo(
          wrapperRef.current,
          textLinesAnimation.from,
          textLinesAnimation.to),
        {
          start: 'top 95%',
        }
      );
    }, 200);

    return () => {
      if (scrollTrigger) {
        scrollTrigger.kill();
      }
    };
  }, []);

  const handleVideoClick = () => {
    modal.showModal(modalNames.VideoModal, {
      standAloneVideo: true,
    }, {
      video: data.video,
      enTitle: data.title,
      poster: data.image.url,
    });
  };

  return (
    <div className={styles.smallImageWrapper} ref={wrapperRef}>
      <img src={data.image.url} alt={data.image.alt} />

      {data.video && (
        <button className={styles.button} onClick={handleVideoClick}>
          <span className={styles.buttonInner}>
            <Icon name="expand" className={styles.buttonIcon} />
            <span className={styles.buttonLabel}>{t('watchVideo')}</span>
          </span>
        </button>
      )}
    </div>
  );
};

export default DetailBlockSmallImage;
