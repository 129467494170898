import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import Quest2Page from './page/Quest2Page';
import AppsAndGamesPage from './page/AppsAndGamesPage';
import AccessoriesPage from './page/AccessoriesPage';
import useGlobal from './store';

const Routes = () => {
  const location = useLocation();
  const [{ locId, accessories }] = useGlobal();
  const hideAccessories = accessories.length === 0 && locId;

  return (
    <Switch>
      <Route exact path={`/`}>
        <Redirect to={{ ...location, pathname: '/quest2' }} />
      </Route>

      <Route exact path={`/quest2`} component={Quest2Page} />
      <Route exact path={`/quest2/apps`} component={AppsAndGamesPage} />
      {hideAccessories ? (
        <Route exact path={`/quest2/accessories`}>
          <Redirect to={{ ...location, pathname: '/quest2' }} />
        </Route>
      ) : (
        <Route exact path={`/quest2/accessories`} component={AccessoriesPage} />
      )}
    </Switch>
  );
};

export default Routes;
