import { createContext } from 'react';

const ModalContext = createContext({
  component: '',
  props: {},
  showModal: () => {},
  hideModal: () => {},
});

export const modalNames = {
  AccessoryDetailModal: 'AccessoryDetailModal',
  GameDetailModal: 'GameDetailModal',
  VideoModal: 'VideoModal',
};

export default ModalContext;
