import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import styles from './secondary-button.module.scss';

const SecondaryButton = ({ label, to, onClick, className }) => {
  if (to) {
    return (
      <span className={`${styles.buttonLink} ${className}`}>
        <Link to={to}>
          <Icon name="small-arrow-left" className={styles.arrow} />
          <span className={styles.label}>{label}</span>
        </Link>
      </span>
    );
  } else if (!to && !onClick) {
    return (
      <span className={`${styles.button} ${className}`} onClick={onClick}>
        <Icon name="small-arrow-left" className={styles.arrow} />
        <span className={styles.label}>{label}</span>
      </span>
    );
  }

  return (
    <button className={`${styles.button} ${className}`} onClick={onClick}>
      <Icon name="small-arrow-left" className={styles.arrow} />
      <span className={styles.label}>{label}</span>
    </button>
  );
};

export default SecondaryButton;
