import React from 'react';
import styles from './close-button.module.scss';
import Icon from '../Icon';
import { useTranslation } from 'react-i18next';

const CloseButton = ({ onClick, className }) => {
  const { t } = useTranslation('global');

  return (
    <button className={`${styles.button} ${className}`} onClick={onClick}>
      <span className={styles.label}>{t('close')}</span>
      <Icon name="close" />
    </button>
  );
};

export default CloseButton;
