import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Icon from '../../../general/Icon';
import Carousel from '../../../block/Carousel';
import ModalContext from '../../../../context/modal/ModalContext';
import useGlobal from '../../../../store';
import { pageUrls, eventActionNames } from '../../../../data/enum/event';
import styles from './game-detail-modal.module.scss';
import CloseButton from '../../../general/CloseButton';

const GameDetailModal = ({
  data: { title, enTitle, copy, carousel, features, detailsList }
}) => {
  const modal = useContext(ModalContext);
  const { logModalLoad, logAction, updateLogPageUrl } = useGlobal()[1];
  const { t } = useTranslation('apps');
  const { pathname } = useLocation();
  const isAppsPage = pathname.includes('/apps');

  useEffect(() => {
    if (isAppsPage) {
      updateLogPageUrl(pageUrls.APPS_GALLERY_GAME_DETAIL);
    }

    logModalLoad({
      name: 'Game Details',
      description: `${enTitle} (${title})`
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseClick = () => {
    modal.hideModal();

    logAction({
      controlName: eventActionNames.CLOSE,
      context: 'GAME DETAILS',
    });

    if (isAppsPage) {
      updateLogPageUrl(pageUrls.APPS_GALLERY);
    }
  };

  if (title && detailsList) {
    return (
      <div className={`modal`}>
        <div className={`${styles.modalContent} modal-content slide-in`}>
          <div className={styles.header}>
            <CloseButton onClick={handleCloseClick} />
          </div>

          <h2 className={styles.title}>{title}</h2>

          <Carousel data={carousel} title={title} enTitle={enTitle} />

          <div className={styles.copyWrapper}>
            <h3
              className={styles.subtitle}
              dangerouslySetInnerHTML={{ __html: t('aboutGame') }}
            />
            <p className={styles.copy} dangerouslySetInnerHTML={{ __html: copy }} />
          </div>

          <ul className={styles.featureList}>
            {features.map(item => (
              <li className={styles.featureListItem} key={item.title}>
                <span className={styles.featureItemIconWrapper}>
                  <span className={`${styles.featureItemIcon} ${item.icon}`}>
                    <Icon name={item.icon} />
                  </span>
                </span>
                <span className={styles.featureItemTitle}>{item.title}</span>
              </li>
            ))}
          </ul>

          <hr className={styles.hr} />

          <h3
            className={styles.detailsListTitle}
            dangerouslySetInnerHTML={{ __html: t('additionalDetails') }}
          />
          <ul>
            {detailsList.map(item => (
              <li
                className={styles.detailItem}
                key={item.key || item.title}
              >
                {item.key ? (
                  <h4 className={styles.detailItemTitle}>{t(`details.${item.key}`)}</h4>
                ) : (
                  <h4 className={styles.detailItemTitle}>{item.title}</h4>
                )}
                <p className={styles.detailItemText}>{item.value}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  return null;
};

export default GameDetailModal;
