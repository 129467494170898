import quillTheater from './quill-theater.json';
import kingsprayGraffiti from './kingspray-graffiti.json';
import tiltBrush from './tilt-brush.json';
import colorSpace from './color-space.json';

const data = {
  quillTheater,
  kingsprayGraffiti,
  tiltBrush,
  colorSpace,
};

export default data;
