import climb2 from './climb2.json';
import supernatural from './supernatural.json';
import vrFishing from './vr-fishing.json';
import topgolf from './topgolf.json';
import scramble from './scramble.json';
import elevenTableTennis from './eleven-table-tennis.json';
import fitXR from './fitXR.json';
import thrillOfFight from './thrill-of-fight.json';

const data = {
  climb2,
  scramble,
  supernatural,
  vrFishing,
  topgolf,
  elevenTableTennis,
  fitXR,
  thrillOfFight,
};

export default data;
