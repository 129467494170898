import beatSaber from './beat-saber.json';
import audica from './audica.json';
import synthRiders from './synth-riders.json';
import danceCentral from './dance-central.json';
import rezInfinite from './rez-infinite.json';

const data = {
  beatSaber,
  audica,
  synthRiders,
  rezInfinite,
  danceCentral,
};

export default data;
