export const eventIds = {
  APP_START: '1000',
  SESSION_START: '1001',
  SESSION_END: '1002',
  PAGE_LOAD: '1003',
  MODAL_LOAD: '1004',
  ACTION: '1005',
  VIDEO_PLAYED: '2001',
  INTERFACE_ERROR: '9000',
};

export const eventDescriptions = {
  [eventIds.APP_START]: 'Application Start',
  [eventIds.SESSION_START]: 'Session Start',
  [eventIds.SESSION_END]: 'Session End',
  [eventIds.PAGE_LOAD]: 'Page Load',
  [eventIds.MODAL_LOAD]: 'Modal Load',
  [eventIds.ACTION]: 'Action',
  [eventIds.VIDEO_PLAYED]: 'Video Played',
  [eventIds.INTERFACE_ERROR]: 'Interface Error',
};

export const pageUrls = {
  APPS: '/apps n games',
  APPS_GALLERY: '/apps n games/gallery',
  APPS_GALLERY_GAME_DETAIL: '/apps n games/gallery/game details',
  QUEST2: '/quest 2',
  ACCESSORIES: '/accessories',
}

export const eventActionNames = {
  CLOSE: 'Close',
  OPEN: 'OPEN',
};
