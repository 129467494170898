import gsap from 'gsap';
import eases from '../../../util/eases';

export const openNavigation = (nav, background, items) => {
  const tl = new gsap.timeline();

  tl.set(nav, { opacity: 1 });

  tl.to(background, {
    duration: 0.2,
    autoAlpha: 1,
  }, 0);

  tl.fromTo(nav, {
    x: '100%'
  }, {
    duration: 0.3,
    ease: eases.VinnieInOut,
    x: '0%',
  }, 0);

  tl.fromTo(items, {
    opacity: 0
  }, {
    duration: 0.2,
    stagger: 0.1,
    opacity: 1
  }, 0.2);
};

export const closeNavigation = (nav, background) => {
  const tl = new gsap.timeline({
    onComplete: () => {
      gsap.set(nav, { opacity: 0 });
    }
  });

  tl.to(nav, {
    duration: 0.3,
    ease: eases.VinnieInOut,
    x: '100%',
  }, 0);
  tl.to(background, {
    duration: 0.2,
    autoAlpha: 0,
  }, 0.2);
};
