import React, { useContext, useEffect, useRef } from 'react';
import ModalContext from '../../../../context/modal/ModalContext';
import useGlobal from '../../../../store';
import styles from './video-modal.module.scss';
import CloseButton from '../../../general/CloseButton';

const VideoModal = ({ isOpen, videoData: { video: videoUrl, enTitle, poster }, standAloneVideo }) => {
  const modal = useContext(ModalContext);
  const { logAction, logVideoPlay } = useGlobal()[1];
  const containerRef = useRef(null);
  const videoRef = useRef(null);
  const trackingRef = useRef({});

  useEffect(() => {
    if(!videoRef.current) {
      return;
    }
    if(isOpen) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
      trackingRef.current = {
        played: 0,
      }
    } else {
      videoRef.current.pause();
      trackingRef.current.played = videoRef.current.currentTime;
    }
  }, [isOpen]);

  useEffect(() => {
    const { visualViewport } = window;
    const viewportHandleResize = (event) => {
      if(event && containerRef.current) {
        const { width, height } = event.target;
        containerRef.current.style.height = width > height ? `${height}px` : '100%';
      }
    }

    if(visualViewport) {
      visualViewport.addEventListener('resize', viewportHandleResize);
      viewportHandleResize();
    }

    return () => {
      if(visualViewport) {
        visualViewport.removeEventListener('resize', viewportHandleResize);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSeeking = () => {
    if( videoRef.current && trackingRef.current ) {
      trackingRef.current.played = Math.max(videoRef.current.currentTime, trackingRef.current.played);
    }
  }

  const handleCloseClick = () => {
    if (standAloneVideo) {
      modal.hideModal();
    } else {
      modal.hideVideoModal();
    }

    logAction({
      controlName: 'Stop Media',
      context: enTitle,
    });

    logVideoPlay({
      name: enTitle,
      duration: videoRef.current.duration,
      played: Math.max(videoRef.current.currentTime, trackingRef.current.played)
      });
  };

  return (
    <div ref={containerRef} className={`${styles.container} ${isOpen ? styles.isOpen : ''}`}>
      <CloseButton onClick={handleCloseClick} className={styles.closeButton} />

      <div className={styles.contentWrapper}>
        <video
          ref={videoRef}
          className={styles.video}
          playsInline
          controls
          preload="true"
          poster={poster}
          onSeeking={onSeeking}
        >
          {videoUrl && <source src={videoUrl} type="video/mp4"/>}
        </video>
      </div>
    </div>
  );
};

export default VideoModal;
