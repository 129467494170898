import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Navigation from '../Navigation';
import NavigationContextProvider from '../../../context/navigation/NavigationContextProvider';
import { useLocation } from 'react-router-dom';

const HeaderContainer = () => {
  const { pathname } = useLocation();
  const [isHeaderVisible, setIsHeaderVisible] = useState(false);

  useEffect(() => {
    if (pathname === '/') {
      setIsHeaderVisible(false);
    } else {
      setIsHeaderVisible(true);
    }
  }, [pathname]);

  if (!isHeaderVisible) {
    return null;
  }
  return (
    <NavigationContextProvider>
      <Header />
      <Navigation />
    </NavigationContextProvider>
  );
};

export default HeaderContainer;
