import React, { useContext, useEffect } from 'react';
import ModalContext from '../../../../context/modal/ModalContext';
import useGlobal from '../../../../store';
import { eventActionNames } from '../../../../data/enum/event';
import styles from './accessory-detail-modal.module.scss';
import CloseButton from '../../../general/CloseButton';

const AccessoryDetailModal = ({
  data: { title, featureBlocks, featuresList }
}) => {
  const modal = useContext(ModalContext);
  const { logModalLoad, logAction } = useGlobal()[1];

  useEffect(() => {
    logModalLoad({
      name: 'Specifications',
      description: 'Oculus Link Cable'
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseClick = () => {
    modal.hideModal();
    logAction({
      controlName: eventActionNames.CLOSE,
      context: 'LINK CABLE',
    });
  };

  if (title && featureBlocks && featuresList) {
    return (
      <div className={`modal`}>
        <div className={`${styles.modalContent} modal-content slide-in`}>
          <div className={styles.header}>
            <CloseButton onClick={handleCloseClick} />
          </div>

          <div className={styles.topWrapper}>
            <h2 className={styles.title}>{title}</h2>

            <ul>
              {featureBlocks.map(item => (
                <li
                  className={styles.featureBlock}
                  key={item.title}
                >
                  <h4 className={styles.featuresTitle}>{item.title}</h4>
                  <p className={styles.featuresBody} dangerouslySetInnerHTML={{ __html: item.copy }} />
                </li>
              ))}
            </ul>
          </div>

          <ul className={styles.specsWrapper}>
            {featuresList.map(item => (
              <li
                className={styles.featuresItem}
                key={item.title}
              >
                <h4 className={styles.specTitle}>{item.title}</h4>
                <p className={styles.specValue}>{item.value}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  return null;
};

export default AccessoryDetailModal;
