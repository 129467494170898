import React, { useState } from 'react';
import NavigationContext from './NavigationContext';

const NavigationContextProvider = ({ children }) => {
  const showNavigation = () => {
    setState({
      ...state,
      isOpen: true,
    });
  };

  const hideNavigation = () => {
    setState({
      ...state,
      isOpen: false,
    });
  };

  const [state, setState] = useState({
    isOpen: false,
    showNavigation,
    hideNavigation,
  });

  return (
    <NavigationContext.Provider value={state}>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationContextProvider;
