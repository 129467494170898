import { format } from "date-fns";

export const getEventTime = (timeOffset = 0) => {
  const d = new Date();
  d.setSeconds(d.getSeconds() + timeOffset);
  return d.toISOString();
};
export const getEventTimeTZFormat = (timeOffset = 0) => {
  const d = new Date();
  d.setSeconds(d.getSeconds() + timeOffset);
  return format(d, "yyyy-MM-dd'T'HH:mm:ssxx");
};
