import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GameList from './components/GameList';
import styles from './games-accordion.module.scss';

const GamesAccordion = ({ translationName }) => {
  const { t } = useTranslation(translationName);
  const data = t('categories', { returnObjects: true });
  // category names in English for tracking
  const categoryNames = t('categories', { lng: 'en-us', returnObjects: true }).map(c => c.name);

  const { hash } = useLocation();
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [scrollToEnabled, setScrollToEnabled] = useState(false);
  const categoryId = t('openCategory', { lng: 'en-us' });

  useEffect(() => {
    if (hash) {
      const urlHash = hash.split('?')[0].replace('#', '');
      setScrollToEnabled(true);
      setSelectedCategoryId(urlHash);
    } else {
      setScrollToEnabled(false);
      setSelectedCategoryId(categoryId);
    }
  }, [hash, categoryId]);

  return (
    <div className={styles.wrapper}>
      <ul>
        {data.map((category, index) => (
          <GameList
            key={category.name}
            data={category}
            categoryData={data}
            categoryName={categoryNames[index]}
            selectedCategoryId={selectedCategoryId}
            setSelectedCategoryId={setSelectedCategoryId}
            scrollToEnabled={scrollToEnabled}
            setScrollToEnabled={setScrollToEnabled}
            gameTranslationName={translationName}
          />
        ))}
      </ul>
    </div>
  );
};

export default GamesAccordion;
