import React, { useState } from 'react';
import ModalContext, { modalNames } from './ModalContext';
import AccessoryDetailModal from '../../components/layout/modals/AccessoryDetailModal';
import GameDetailModal from '../../components/layout/modals/GameDetailModal';
import VideoModal from '../../components/layout/modals/VideoModal';
import { disableScroll } from '../../util/element';

let prevScrollY = typeof window === 'object' ? window.pageYOffset : 0;

const ModalContextProvider = ({ children }) => {
  const showModal = (modalName, data = {}, videoData = {}) => {
    setState({
      ...state,
      isOpen: true,
      component: modalName,
      data,
      videoData,
    });

    prevScrollY = window.pageYOffset;
    disableScroll(true, prevScrollY);
  };

  const hideModal = () => {
    setState({
      ...state,
      isOpen: false,
      component: '',
      data: {},
      videoData: {},
    });

    disableScroll(false, prevScrollY);
  };

  const showVideoModal = (videoData) => {
    setState({
      ...state,
      isVideoOpen: true,
      videoData,
    });
  };

  const hideVideoModal = () => {
    setState({
      ...state,
      isVideoOpen: false,
    });
  };

  const [state, setState] = useState(
    {
      isOpen: false,
      isVideoOpen: false,
      component: '',
      data: {},
      videoData: {},
    }
  );

  const value = {
    state,
    showModal,
    hideModal,
    showVideoModal,
    hideVideoModal,
  }

  return (
    <ModalContext.Provider value={value}>
      {children}

      {state.component === modalNames.GameDetailModal && (
        <>
          <GameDetailModal
            isOpen={state.isOpen}
            data={state.data}
          />
          <VideoModal isOpen={state.isVideoOpen} videoData={state.videoData} />
        </>
      )}

      {state.component === modalNames.AccessoryDetailModal && (
        <AccessoryDetailModal
          isOpen={state.isOpen}
          data={state.data}
        />
      )}

      {state.component === modalNames.VideoModal && (
        <VideoModal
          isOpen={state.isOpen}
          videoData={state.videoData}
          standAloneVideo={state.data.standAloneVideo}
        />
      )}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
