import climb2 from './climb2.json';
import racketFury from './racket-fury.json';
import scramble from './scramble.json';
import fitXr from './fit-xr.json';
import elevenTableTennis from './eleven-table-tennis.json';
import carveSnowboarding from './carve-snowboarding.json';

const data = {
  climb2,
  racketFury,
  scramble,
  fitXr,
  elevenTableTennis,
  carveSnowboarding,
};

export default data;
