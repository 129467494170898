import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import scrollTransition from '../../../util/transitions/scrollTransition';
import styles from './hero.module.scss';
import Icon from '../../general/Icon';
import useIsSelected from '../../../util/hooks/useIsSelected';
import { textLinesAnimation } from '../../../util/transitions/animationUtils';

gsap.registerPlugin(ScrollToPlugin);

const Hero = ({ eyebrow, title, description, disclaimer, button, background, isSelected }) => {
  const wrapperRef = useRef(null);
  const eyebrowRef = useRef(null);
  const titleRef = useRef(null);
  const copyRef = useRef(null);
  const disclaimerRef = useRef(null);
  const buttonRef = useRef(null);
  const heroHeight = window.innerHeight - 64; // 64 = Header height

  useIsSelected(isSelected, wrapperRef.current);

  useEffect(() => {
    let scrollTrigger;

    if (!wrapperRef.current) {
      return;
    }

    const animationElements = [];
    eyebrowRef.current && animationElements.push(eyebrowRef.current);
    titleRef.current && animationElements.push(titleRef.current);
    copyRef.current && animationElements.push(copyRef.current);
    disclaimerRef.current && animationElements.push(disclaimerRef.current);
    buttonRef.current && animationElements.push(buttonRef.current);

    gsap.set(animationElements, textLinesAnimation.from);

    setTimeout(() => {
      scrollTrigger = scrollTransition(
        wrapperRef.current,
        gsap.fromTo(animationElements, textLinesAnimation.from, textLinesAnimation.to),
      );
    }, 200);

    return () => {
      if (scrollTrigger) {
        scrollTrigger.kill();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = () => {
    const elmRect = wrapperRef.current.getBoundingClientRect();
    const newScrollPosition = wrapperRef.current.offsetTop + elmRect.height - 60;

    gsap.to(window, { duration: 0.4, scrollTo: newScrollPosition });
  };

  return (
    <section
      ref={wrapperRef}
      className={styles.hero}
      style={{
        backgroundImage: `url(${background})`,
        height: `${heroHeight}px`
      }}
    >
      <div className={styles.contentWrapper}>
        <h2 className={styles.eyebrow} ref={eyebrowRef}><span>{eyebrow}</span></h2>
        <div className={styles.content}>
          <h2
            ref={titleRef}
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          {description && <p className={styles.copy} ref={copyRef} dangerouslySetInnerHTML={{ __html: description }} />}
          {disclaimer && <p className={styles.disclaimer} ref={disclaimerRef} dangerouslySetInnerHTML={{ __html: disclaimer }} />}
        </div>

        <button
          className={`${styles.button} ${!description ? styles.hasLargeMargin : ''}`}
          onClick={handleClick}
          ref={buttonRef}
        >
          <Icon name="small-arrow-left" className={styles.arrow} />
          <span className={styles.label}>{button}</span>
        </button>
      </div>
    </section>
  );
};

export default Hero;
