import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import DetailBlock from '../../components/block/DetailBlock';
import { aspectRatioTypes } from '../../components/block/DetailBlock/DetailBlock';
import ModalContext, { modalNames } from '../../context/modal/ModalContext';
import { idToTrackLabel, toCamel } from '../../util/stringUtils';
import useGlobal from '../../store';
import { pageUrls } from '../../data/enum/event';
import styles from './accessories-page.module.scss';
import ProductGrid from '../../components/block/ProductGrid';
import PcGamesCarousel from '../../components/block/PcGamesCarousel';
import Explore from '../../components/block/Explore';
import BackToTopButton from '../../components/general/BackToTopButton';

const AccessoriesPage = () => {
  const modal = useContext(ModalContext);
  const [{ locId, accessories }, { updateLogPageName, logAction }] = useGlobal();
  const { t } = useTranslation('accessories');
  const { t: globalT } = useTranslation('global');
  const list = t('list', { returnObjects: true });
  const productGrid = t('products.list', { returnObjects: true });
  // Filter accessories list based on the location
  const filteredList = locId
    ? list.filter(({ tag }) => accessories.find(element => element === tag))
    : list;
  const filteredProducts = locId
    ? productGrid.filter(({ tag }) => accessories.find(element => element === tag))
    : productGrid;
  const gameCarousel = t('gameCarousel', { returnObjects: true });

  const { t: appsT } = useTranslation('apps');
  const games = appsT('games', { returnObjects: true });
  const gameCarouselList = gameCarousel.list.map(gameId => {
    const { title, image, carousel } = games[`${toCamel(gameId)}`];
    return { id: gameId, title, image: image || carousel[0].image };
  });

  const { hash } = useLocation();
  const [selectedAccessoryId, setSelectedAccessoryId] = useState(null);
  const accessoryId = null;

  useEffect(() => {
    if (hash) {
      const urlHash = hash.split('?')[0].replace('#', '');
      setSelectedAccessoryId(urlHash);
    } else {
      setSelectedAccessoryId(accessoryId);
    }
  }, [hash, accessoryId]);


  useEffect(() => {
    updateLogPageName(pageUrls.ACCESSORIES);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGameSlideClick = ({ id }) => {
    // Open game detail modal
    const data = games[`${toCamel(id)}`];
    const { title: enTitle } = appsT(`games.${toCamel(id)}`, { returnObjects: true, lng: 'en-us' });

    logAction({
      controlName: enTitle,
      context: 'Page',
    });
    modal.showModal(modalNames.GameDetailModal, { ...data, enTitle, });
  };

  return (
    <div className={styles.wrapper}>
      <ProductGrid
        id={t('products.id')}
        title={t('products.title')}
        items={filteredProducts}
        buttonLabel={t('products.buttonLabel')}
        isSelected={t('products.id') === selectedAccessoryId}
        marquee={t('products.marquee')}
      />

      {filteredList.map(item => (
        <DetailBlock
          key={item.title}
          data={item}
          trackPath={idToTrackLabel(item.id)}
          trackScrollOptions={item.id === 'oculus-link-cable' && { onEnterBack: null }}
          contentOffsetTop={item.contentOffsetTop}
          aspectRatio={aspectRatioTypes.default}
          imageCarouselAspectRatio={item.imageCarouselAspectRatio}
          imageAnimationDirection={item.animationDirection}
          isSelected={item.id === selectedAccessoryId}
        />
      ))}

      <div className={styles.gamesCarouselWrapper}>
        {/* keep 'Unlock pc vr games' as part of 'accessories/oculus link cable' track page url */}
        <PcGamesCarousel
          data={gameCarouselList}
          title={gameCarousel.title}
          trackScroll={true}
          trackScrollOptions={{ onEnter: null }}
          trackPath={'oculus link cable'}
          onSlideClick={handleGameSlideClick}
        />
      </div>

      <Explore
        data={globalT('exploreQuest2', { returnObjects: true })}
        translationName="exploreQuest2"
        translationFile="global"
      />
      <div className={styles.exploreWrapper}>
        <Explore
          data={globalT('exploreAppsAndGames', { returnObjects: true })}
          translationName="exploreAppsAndGames"
          translationFile="global"
        />
      </div>

      <BackToTopButton />
    </div>
  );
};

export default AccessoriesPage;
