import quillTheater from './quill-theater.json';
import tiltBrush from './tilt-brush.json';
import gravitySketch from './gravity-sketch.json';
import kingsprayGraffiti from './kingspray-graffiti.json';

const data = {
  quillTheater,
  tiltBrush,
  gravitySketch,
  kingsprayGraffiti,
};

export default data;
