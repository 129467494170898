import tetrisEffect from './tetris-effect.json';
import angryBirdsVr from './angry-birds-vr.json';
import lastLabyrinth from './last-labyrinth.json';
import fruitNinja from './fruit-ninja.json';
import puzzleBobble from './puzzle-bobble.json';
import epicRollerCoasters from './epic-roller-coasters.json';

const data = {
  tetrisEffect,
  angryBirdsVr,
  lastLabyrinth,
  fruitNinja,
  puzzleBobble,
  epicRollerCoasters
};

export default data;
