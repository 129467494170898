import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styles from './menu-link.module.scss';
import { Link, useLocation } from 'react-router-dom';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import useGlobal from '../../../../store';
import eases from '../../../../util/eases';
import Icon from '../../../general/Icon';
import NavigationContext from '../../../../context/navigation/NavigationContext';
import Query from '../../../../data/enum/query';
import MenuSubLink from './MenuSubLink';

gsap.registerPlugin(ScrollToPlugin);

const MenuLink = ({ item: { label, enLabel, link, subNav }, onLinkClick, handleOpenClose, index, openMenuItem }) => {
  const navigationContext = useContext(NavigationContext);
  const { pathname } = useLocation();
  const [{ locId, accessories }, { logAction }] = useGlobal();
  const params = locId ? `?${Query.LOCATION_ID}=${locId}` : '';
  const [isActive, setIsActive] = useState(false);
  const [isOtherMenuLinkOpen, setIsOtherMenuLinkOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  if (link === '/accessories') {
    subNav = locId ? subNav.filter(({ tag }) => accessories.find(element => element === tag)) : subNav;
  }

  const firstRenderRef = useRef(true);
  const wrapperRef = useRef(null);
  const listRef = useRef(null);

  useEffect(() => {
    if (openMenuItem !== -1) {
      if (openMenuItem !== index) {
        setIsOpen(false);
        setIsOtherMenuLinkOpen(true);

        return;
      }
    } else {
      setIsOpen(false);
    }
    setIsOtherMenuLinkOpen(false);
  }, [openMenuItem, index]);

  useEffect(() => {
    setIsActive(pathname === link);
  }, [pathname, link]);

  useEffect(() => {
    if (!navigationContext.isOpen) {
      setIsOpen(false);
    }
  }, [navigationContext.isOpen]);

  useEffect(() => {
    if (!subNav) {
      return;
    }

    if (isOpen) {
      gsap.to(wrapperRef.current, {
        duration: 0.5,
        ease: eases.VinnieInOut,
        height: listRef.current.offsetHeight
      });
    } else {
      if (firstRenderRef.current) {
        firstRenderRef.current = false;
        gsap.set(wrapperRef.current, {
          height: 0
        });
      } else {
        gsap.to(wrapperRef.current, {
          duration: 0.5,
          ease: eases.VinnieInOut,
          height: 0
        });
      }
    }
  }, [isOpen, subNav]);

  const handleArrowButtonClick = useCallback(() => {
    handleOpenClose(index, !isOpen);
    setIsOpen(!isOpen);
  }, [isOpen, handleOpenClose, index]);

  const onItemClick = (e) => {
    onLinkClick();
    logAction({
      controlName: e.target.dataset.label.toUpperCase(),
      context: 'Nav',
    });
  }

  const onMainNavLinkClick = (e) => {
    onItemClick(e);

    if (e.target.dataset.link === pathname) {
      gsap.to(window, { duration: 0.4, scrollTo: 0 });
    }
  };

  return (
    <div className={`${styles.mainLink} heading-07 ${isActive ? styles.itemIsActive : ''} ${isOtherMenuLinkOpen ? styles.otherItemOpen : ''} ${isOpen ? styles.itemIsOpen : ''}`}>
      <div className={`${styles.link} ${subNav && styles.hasSubNav}`}>
        <Link to={`${link}${params}`} data-link={link} data-label={enLabel || label} onClick={onMainNavLinkClick}>{label}</Link>
      </div>

      {subNav && (
        <>
          <button
            className={`${styles.arrowButton} ${isOpen ? styles.isOpen : ''}`}
            onClick={handleArrowButtonClick}
          >
            <span className={styles.icon}>
              <Icon name="small-arrow-left" />
            </span>
          </button>

          <div className={`${styles.subNavWrapper} ${isOpen ? styles.subNavIsOpen : ''}`} ref={wrapperRef}>
            <ul className={styles.subNavList} ref={listRef}>
              {subNav.map(item => <MenuSubLink key={item.label} item={item} onClick={onItemClick} />)}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default MenuLink;
