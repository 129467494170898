import apps from './apps.json';
import actionAdventure from './action-adventure';
import arcadePuzzles from './arcade-puzzles';
import artsCreativity from './arts-creativity';
import musicRhythm from './music-entertainment';
import sportsFitness from './sports-fitness';
import pcVr from './pc-vr';

const data = {
  ...apps,
  games: {
    ...actionAdventure,
    ...arcadePuzzles,
    ...artsCreativity,
    ...musicRhythm,
    ...sportsFitness,
    ...pcVr,
  }
};

export default data;
