import paperBeast from './paper-beast.json';
import asgardsWrath from './asgards-wrath.json';
import loneEcho from './lone-echo.json';

const data = {
  paperBeast,
  asgardsWrath,
  loneEcho,
};

export default data;
