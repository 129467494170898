import fiveNights from './five-nights.json';
import inDeath from './in-death.json';
import theRoomVr from './the-room-vr.json';
import downTheRabbitHole from './down-the-rabbit-hole.json';

const data = {
  fiveNights,
  inDeath,
  theRoomVr,
  downTheRabbitHole,
};

export default data;
