import React, { useEffect, useState } from 'react';
import GamesAccordion from '../../components/block/GamesAccordion';
import useGlobal from '../../store';
import { pageUrls } from '../../data/enum/event';
import styles from './AppsAndGamesPage.module.scss';
import Hero from '../../components/block/Hero';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Explore from '../../components/block/Explore';
import BackToTopButton from '../../components/general/BackToTopButton';

const AppsAndGamesPage = () => {
  const { t } = useTranslation('apps');
  const { t: globalT } = useTranslation('global');
  const [{ locId, accessories } , { updateLogPageName }] = useGlobal();
  const hideAccessories = accessories.length === 0 && locId;

  const { hash } = useLocation();
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const categoryId = null;

  useEffect(() => {
    if (hash) {
      const urlHash = hash.split('?')[0].replace('#', '');
      setSelectedCategoryId(urlHash);
    } else {
      setSelectedCategoryId(categoryId);
    }
  }, [hash, categoryId]);

  useEffect(() => {
    updateLogPageName(pageUrls.APPS);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Hero
        eyebrow={t('hero.eyebrow')}
        title={t('hero.title')}
        description={t('hero.description')}
        disclaimer={t('hero.disclaimer')}
        button={t('hero.button')}
        background={t('hero.background')}
        isSelected={t('hero.id') === selectedCategoryId}
      />
      <GamesAccordion translationName="apps" />

      <Explore
        data={globalT('exploreQuest2', { returnObjects: true })}
        translationName="exploreQuest2"
        translationFile="global"
      />

      {!hideAccessories && (
        <div className={styles.exploreWrapper}>
          <Explore
            data={globalT('exploreAccessories', { returnObjects: true })}
            translationName="exploreAccessories"
            translationFile="global"
          />
        </div>
      )}

      <BackToTopButton />
    </div>
  );
};

export default AppsAndGamesPage;
