const carousel = {
  config: {
    type: 'x',
    zIndexBoost: false,
    inertia: true,
    minimumMovement: 12,
    maxDuration: 1,
    dragResistance: 0.2,
    edgeResistance: 0.95,
  }
};

export default carousel;
