export const toCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1
      .toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

export const idToTrackLabel = (s) => s.replace(/-/g, ' ');
