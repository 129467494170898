const initialState = {
  origin: null,
};

const actions = {
  setDeviceType: async(store, origin) => {
    store.setState({
      ...store.state,
      origin,
    });
    return origin;
  },
};

const deviceType = {
  actions,
  initialState,
};

export default deviceType;