import asgardsWrath from './asgards-wrath.json';
import loneEcho from './lone-echo.json';
import paperBeast from './paper-beast.json';

const data = {
  asgardsWrath,
  loneEcho,
  paperBeast,
};

export default data;
