import React, { useEffect, useRef, useState } from 'react';
import { gsap, Power3, TweenMax } from 'gsap';
import Draggable from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';
import Slide from './components/Slide';
import scrollTransition from '../../../util/transitions/scrollTransition';
import { textLinesShortAnimation } from '../../../util/transitions/animationUtils';
import useWindowResize from '../../../util/hooks/use-window-resize';
import carousel from '../../../data/config/carousel';
import scrollTrack from '../../../util/scrollTrack';
import useGlobal from '../../../store';
import styles from './image-carousel.module.scss';
import Icon from '../../general/Icon';

gsap.registerPlugin(Draggable, InertiaPlugin);

export const imageCarouselAspectRatioType = {
  square: 'square',
  rectangle: 'rectangle'
};

const ImageCarousel = ({ aspectRatio, data, isDark, title, trackScroll, trackPath, trackScrollOptions, onSlideClick }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const { logAction, logPageScroll } = useGlobal()[1];

  const draggerRef = useRef();
  const slideWidthRef = useRef();

  const containerRef = useRef(null);
  const sliderRef = useRef(null);
  const slidesRef = useRef({});

  const wrapperRef = useRef(null);
  const titleRef = useRef(null);
  const bulletListRef = useRef(null);

  useEffect(() => {
    let scrollTrigger;
    let scrollTriggerSlider;
    let scrollTriggerTrack;

    const elements = [];
    if (titleRef.current) {
      elements.push(titleRef.current);
    }
    if (bulletListRef.current) {
      elements.push(bulletListRef.current);
    }

    gsap.set(elements, textLinesShortAnimation.from);
    gsap.set(containerRef.current, { x: '100%' });

    setTimeout(() => {
      if(!wrapperRef.current) {
        return;
      }

      scrollTrigger = scrollTransition(
        wrapperRef.current,
        gsap.fromTo(
          elements,
          textLinesShortAnimation.from,
          textLinesShortAnimation.to)
      );

      scrollTriggerSlider = scrollTransition(
        wrapperRef.current,
        gsap.fromTo(
          containerRef.current,
          { x: '100%' },
          {
            x: '0%',
            duration: 0.8,
            ease: Power3.easeOut,
          })
      );

      if (trackScroll) {
        scrollTriggerTrack = scrollTrack(wrapperRef.current, () => {
          logPageScroll(trackPath.toUpperCase(), trackPath);
        }, trackScrollOptions);
      }

    }, 200);

    return () => {
      if (scrollTrigger) {
        scrollTrigger.kill();
      }
      if (scrollTriggerSlider) {
        scrollTriggerSlider.kill();
      }
      if (scrollTriggerTrack) {
        scrollTriggerTrack.kill();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let activeIndex = 0;
    slideWidthRef.current = slidesRef.current[0] ? slidesRef.current[0].offsetWidth : 0;

    draggerRef.current = Draggable.create(sliderRef.current, {
      ...carousel.config,
      dragClickables: true,
      bounds: {
        minX: -( slideWidthRef.current * (data.length - 1)),
        maxX: 0,
        minY: 0,
        maxY: 0,
      },
      snap: (x) => {
        const snap = gsap.utils.snap( slideWidthRef.current, x);
        let newIndex = snap / - slideWidthRef.current;

        if (newIndex < 0) {
          newIndex = 0;
        } else if (newIndex > (data.length - 1)) {
          newIndex = data.length - 1;
        }
        setActiveSlide(newIndex);

        if(activeIndex !== newIndex) {
          activeIndex = newIndex;
          logAction({
            controlName: `position: ${newIndex+1}`,
            context: 'Page',
            type: 'swipe',
          });
        }
        return snap;
      },
    })[0];

    return () => {
      if(draggerRef.current) {
        draggerRef.current.kill();
        draggerRef.current = null;
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useWindowResize(() => {
    // Reset slide width, activeSlide index and dragger bounds
    slideWidthRef.current = slidesRef.current[0] ? slidesRef.current[0].offsetWidth : 0;

    if(draggerRef.current) {
      draggerRef.current.applyBounds({
        minX: -( slideWidthRef.current * (data.length - 1)),
        maxX: 0,
        minY: 0,
        maxY: 0,
      });

      goto(0, false);
    }
  });

  const goto = (index, clicked = true) => {
    if(!slidesRef.current[0]) {
      return;
    }

    draggerRef.current.x = gsap.getProperty(sliderRef.current, 'x');

    TweenMax.to(draggerRef.current, 0.5, {
      x: index * -1 * slideWidthRef.current,
      onUpdate: () => {
        TweenMax.set(sliderRef.current, { x: draggerRef.current.x });
      }
    });

    setActiveSlide(index);

    if(clicked) {
      logAction({
        controlName: `position: ${index+1}`,
        context: 'Page',
      });
    }
  }

  const handleBulletItemClick = (e) => {
    goto(parseInt(e.target.dataset.slideIndex));
  };

  const handleClickPrevious = () => {
    if (activeSlide === 0) {
      goto(data.length - 1);
    } else {
      goto(activeSlide - 1);
    }
  };

  const handleClickNext = () => {
    if (activeSlide === data.length - 1) {
      goto(0);
    } else {
      goto(activeSlide + 1);
    }
  };

  return (
    <div className={`${styles.wrapper} ${isDark ? styles.isDark : ''}`} ref={wrapperRef}>
      {title && <h3 className={styles.title} ref={titleRef} dangerouslySetInnerHTML={{ __html: title }} />}

      <div
        className={styles.sliderContainer}
        ref={containerRef}
      >
        <ul ref={sliderRef}>
          {data.map((item, index) => (
            <li
              className={styles.sliderListItem}
              ref={(element) => slidesRef.current[index] = element}
              key={item.name || item.title}
              style={{ transform: item.image.offset ? `translateX(${item.image.offset})` : '' }}
            >
              <Slide
                data={item}
                aspectRatio={aspectRatio}
                onSlideClick={onSlideClick}
              />
            </li>
          ))}
        </ul>
      </div>

      <div className={styles.paginationWrapper}>
        <button className={styles.paginationButton} onClick={handleClickPrevious}>
          <Icon className={styles.chevronIcon} name="small-arrow-left" />
        </button>
        <ul className={styles.bulletList} ref={bulletListRef}>
          {data.map((item, index) => (
            <li
              className={`${styles.bulletItem} ${activeSlide === index ? styles.isActive : ''}`}
              key={`bullet ${item.name || item.title}`}
              data-slide-index={index}
              onClick={handleBulletItemClick}
            />
          ))}
        </ul>
        <button className={styles.paginationButton} onClick={handleClickNext}>
          <Icon className={styles.chevronIcon} name="small-arrow-left" />
        </button>
      </div>
    </div>
  );
};

export default ImageCarousel;
