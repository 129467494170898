import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DetailBlock from '../../components/block/DetailBlock';
import { aspectRatioTypes, animationDirection } from '../../components/block/DetailBlock/DetailBlock';
import GetReady from '../../components/block/GetReady';
import ProductGrid from '../../components/block/ProductGrid';
import useGlobal from '../../store';
import { pageUrls } from '../../data/enum/event';
import styles from './quest-2.module.scss';
import Hero from '../../components/block/Hero';
import { useLocation } from 'react-router-dom';
import Explore from '../../components/block/Explore';
import BackToTopButton from '../../components/general/BackToTopButton';

const Quest2Page = () => {
  const { t } = useTranslation('quest2');
  const { t: globalT } = useTranslation('global');
  const [{ locId, accessories } , { updateLogPageName }] = useGlobal();
  const hideAccessories = accessories.length === 0 && locId;

  const { hash } = useLocation();
  const [activeHashId, setActiveHashId] = useState(null);
  const accessoryId = null;

  useEffect(() => {
    if (hash) {
      const urlHash = hash.split('?')[0].replace('#', '');
      setActiveHashId(urlHash);
    } else {
      setActiveHashId(accessoryId);
    }
  }, [hash, accessoryId]);

  useEffect(() => {
    updateLogPageName(pageUrls.QUEST2);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.wrapper}>
      <Hero
        id={t('hero.id')}
        eyebrow={t('hero.eyebrow')}
        title={t('hero.title')}
        description={t('hero.description')}
        disclaimer={t('hero.disclaimer')}
        button={t('hero.button')}
        background={t('hero.background')}
        isSelected={t('hero.id') === activeHashId}
      />

      <DetailBlock
        data={{
          id: t('display.id'),
          eyebrow: t('display.eyebrow'),
          title: t('display.title'),
          copy: t('display.description'),
          image: { url: '/asset/image/quest-2/headset.png', alt: 'Headset' },
          smallImage: {
            title: t('display.title'),
            image: {
              url: '/asset/image/quest-2/headset-small.jpg',
              alt: 'Headset',
            },
            video: t('display.video'),
          },
        }}
        aspectRatio={aspectRatioTypes.large}
        imageAnimationDirection={animationDirection.left}
        trackPath={'display'}
        isSelected={t('display.id') === activeHashId}
      />

      <DetailBlock
        data={{
          id: t('controller.id'),
          eyebrow: t('controller.eyebrow'),
          title: t('controller.title'),
          copy: t('controller.description'),
          images: [
            { url: '/asset/image/quest-2/controller-02.png', alt: 'Quest 2 Controller' },
            { url: '/asset/image/quest-2/controller-01.png', alt: 'Quest 2 Controller' }
          ],
          smallImage: {
            title: t('controller.title'),
            image: {
              url: '/asset/image/quest-2/controllers-small.jpg',
              alt: 'Quest 2 Controllers',
            },
            video: t('controller.video'),
          },
        }}
        aspectRatio={aspectRatioTypes.large}
        imageAnimationDirection={animationDirection.left}
        trackPath={'controllers'}
        isSelected={t('controller.id') === activeHashId}
      />

      <DetailBlock
        data={{
          id: t('processor.id'),
          eyebrow: t('processor.eyebrow'),
          title: t('processor.title'),
          copy: t('processor.description'),
          image: { url: '/asset/image/quest-2/exploded-headset.jpg', alt: 'Headset showing all electronic components' },
          smallImage: {
            title: t('processor.title'),
            image: {
              url: '/asset/image/quest-2/performance-small.jpg',
              alt: 'Quest 2 Headset',
            },
            video: t('processor.video'),
          },
        }}
        aspectRatio={aspectRatioTypes.large}
        imageAnimationDirection={animationDirection.left}
        trackPath={'performance'}
        isSelected={t('processor.id') === activeHashId}
      />

      <GetReady isSelected={t('setup.id') === activeHashId} />
      <ProductGrid
        id={t('included.id')}
        title={t('included.title')}
        items={t('included.list', { returnObjects: true })}
        isSelected={t('included.id') === activeHashId}
        trackName="WHAT'S INCLUDED"
        trackPath="whats included"
      />

      {hideAccessories ? (
        <Explore
          data={globalT('exploreAppsAndGames', { returnObjects: true })}
          translationName="exploreAppsAndGames"
          translationFile="global"
        />
      ) : (
        <>
          <Explore
            data={globalT('exploreAccessories', { returnObjects: true })}
            translationName="exploreAccessories"
            translationFile="global"
          />
          <div className={styles.exploreWrapper}>
            <Explore
              data={globalT('exploreAppsAndGames', { returnObjects: true })}
              translationName="exploreAppsAndGames"
              translationFile="global"
            />
          </div>
        </>
      )}

      <BackToTopButton />
    </div>
  );
};

export default Quest2Page;
