import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';
import Slide from './components/Slide';
import styles from './carousel.module.scss';
import useWindowResize from '../../../util/hooks/use-window-resize';
import carousel from '../../../data/config/carousel';
import useGlobal from '../../../store';

gsap.registerPlugin(Draggable, InertiaPlugin);

const Carousel = ({ aspectRatio, data, title, enTitle }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const { logAction } = useGlobal()[1];

  const draggerRef = useRef();
  const slideWidthRef = useRef();

  const containerRef = useRef(null);
  const sliderRef = useRef(null);
  const slidesRef = useRef({});

  useEffect(() => {
    let activeIndex = 0;
    slideWidthRef.current = slidesRef.current[0] ? slidesRef.current[0].offsetWidth : 0;

    draggerRef.current = Draggable.create(sliderRef.current, {
      ...carousel.config,
      bounds: {
        minX: -(slideWidthRef.current * (data.length - 1)),
        maxX: 0,
        minY: 0,
        maxY: 0,
      },
      snap: (x) => {
        const snap = gsap.utils.snap(slideWidthRef.current, x);
        let newIndex = snap / -slideWidthRef.current;

        if (newIndex < 0) {
          newIndex = 0;
        } else if (newIndex > (data.length - 1)) {
          newIndex = data.length - 1;
        }

        setActiveSlide(newIndex);

        if(activeIndex !== newIndex) {
          activeIndex = newIndex;
          logAction({
            controlName: `position: ${newIndex+1}`,
            context: enTitle,
            type: 'swipe',
          });
        }
        return snap;
      },
    })[0];

    return () => {
      if(draggerRef.current) {
        gsap.killTweensOf(draggerRef.current);
        draggerRef.current.kill();
        draggerRef.current = null;
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useWindowResize(() => {
    // Reset slide width, activeSlide index and dragger bounds
    slideWidthRef.current = slidesRef.current[0] ? slidesRef.current[0].offsetWidth : 0;

    if(draggerRef.current) {
      draggerRef.current.applyBounds({
        minX: -( slideWidthRef.current * (data.length - 1)),
        maxX: 0,
        minY: 0,
        maxY: 0,
      });

      goto(0, false);
    }
  });

  const goto = (index, clicked = true) => {
    if(!slidesRef.current[0]) {
      return;
    }

    draggerRef.current.x = gsap.getProperty(sliderRef.current, 'x');

    gsap.to(draggerRef.current, {
      duration: 0.5,
      x: index * -1 * slideWidthRef.current,
      onUpdate: () => {
        gsap.set(sliderRef.current, { x: draggerRef.current.x });
      }
    });

    setActiveSlide(index);

    if(clicked) {
      logAction({
        controlName: `position: ${index+1}`,
        context: enTitle,
      });
    }
  }

  const handleBulletItemClick = (e) => {
    goto(parseInt(e.target.dataset.slideIndex));
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.sliderContainer}
        ref={containerRef}
      >
        <ul ref={sliderRef}>
          {data.map((item, index) => (
            <li
              className={styles.sliderListItem}
              ref={(element) => slidesRef.current[index] = element}
              key={`${title} ${index + 1}`}
            >
              <Slide
                data={item}
                aspectRatio={aspectRatio}
                title={`${title} ${index + 1}`}
                enTitle={enTitle}
              />
            </li>
          ))}
        </ul>
      </div>

      { data.length > 1 &&
        <ul className={styles.bulletList}>
          {data.map((item, index) => (
            <li
              className={`${styles.bulletItem} ${activeSlide === index ? styles.isActive : ''}`}
              key={`Slide ${index + 1}`}
              data-slide-index={index}
              onClick={handleBulletItemClick}
            />
          ))}
        </ul>
      }
    </div>
  );
};

export default Carousel;
