import littleWitchAcademia from './little-witch-academia.json';
import swordsOfGargantua from './swords-of-gargantua.json';
import altdeus from './altdeus.json';
import population from './population.json';
import starWars from './star-wars.json';
import moss from './moss.json';
import residentEvil4 from './resident-evil-4.json';
import medalOfHonor from './medal-of-honor.json';
import amongUs from './among-us.json';
import horizonWorkrooms from './horizon-workrooms.json';
import altairBreaker from './altair-breaker.json';
import ruinsMagus from './ruins-magus.json';
import uNextVr from './u-next-vr.json';
import theWalkingDead from './the-walking-dead.json';

const data = {
  littleWitchAcademia,
  swordsOfGargantua,
  altdeus,
  population,
  starWars,
  moss,
  residentEvil4,
  medalOfHonor,
  amongUs,
  horizonWorkrooms,
  altairBreaker,
  ruinsMagus,
  uNextVr,
  theWalkingDead,
};

export default data;
