import CustomEase from 'gsap/CustomEase';
import gsap from 'gsap';

gsap.registerPlugin(CustomEase);

const VinnieInOut = CustomEase.create('VinnieInOut', 'M0,0 C0.2,0 0,1 1,1');

const eases = {
  VinnieInOut,
};

export default eases;
