import React, { useEffect, useRef } from 'react';
import { gsap, Power3 } from 'gsap';
import Draggable from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';
import scrollTransition from '../../../util/transitions/scrollTransition';
import { textLinesShortAnimation } from '../../../util/transitions/animationUtils';
import carousel from '../../../data/config/carousel';
import scrollTrack from '../../../util/scrollTrack';
import useGlobal from '../../../store';
import styles from './pc-games-carousel.module.scss';

gsap.registerPlugin(Draggable, InertiaPlugin);

const SlideButton = ({ item, onSlideClick }) => {
  const handleClick = () => {
    onSlideClick(item);
  };

  return (
    <button className={styles.slideButton} onClick={handleClick}>
      <img src={item.image.url} alt={item.image.alt || item.name || item.title} />
    </button>
  );
};

const PcGamesCarousel = ({ data, title, trackScroll, trackPath, trackScrollOptions, onSlideClick }) => {
  const { logPageScroll } = useGlobal()[1];

  const draggerRef = useRef();

  const containerRef = useRef(null);
  const sliderRef = useRef(null);
  const slidesRef = useRef({});

  const wrapperRef = useRef(null);
  const titleRef = useRef(null);
  const bulletListRef = useRef(null);

  useEffect(() => {
    let scrollTrigger;
    let scrollTriggerSlider;
    let scrollTriggerTrack;

    const elements = [];
    if (titleRef.current) {
      elements.push(titleRef.current);
    }
    if (bulletListRef.current) {
      elements.push(bulletListRef.current);
    }

    gsap.set(elements, textLinesShortAnimation.from);
    gsap.set(containerRef.current, { x: '100%' });

    setTimeout(() => {
      if(!wrapperRef.current) {
        return;
      }

      scrollTrigger = scrollTransition(
        wrapperRef.current,
        gsap.fromTo(
          elements,
          textLinesShortAnimation.from,
          textLinesShortAnimation.to)
      );

      scrollTriggerSlider = scrollTransition(
        wrapperRef.current,
        gsap.fromTo(
          containerRef.current,
          { x: '100%' },
          {
            x: '0%',
            duration: 0.8,
            ease: Power3.easeOut,
          })
      );

      if (trackScroll) {
        scrollTriggerTrack = scrollTrack(wrapperRef.current, () => {
          logPageScroll(trackPath.toUpperCase(), trackPath);
        }, trackScrollOptions);
      }

    }, 200);

    return () => {
      if (scrollTrigger) {
        scrollTrigger.kill();
      }
      if (scrollTriggerSlider) {
        scrollTriggerSlider.kill();
      }
      if (scrollTriggerTrack) {
        scrollTriggerTrack.kill();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    draggerRef.current = Draggable.create(sliderRef.current, {
      ...carousel.config,
      dragClickables: true,
      bounds: containerRef.current,
    })[0];

    return () => {
      if(draggerRef.current) {
        draggerRef.current.kill();
        draggerRef.current = null;
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      {title && <h3 className={styles.title} ref={titleRef} dangerouslySetInnerHTML={{ __html: title }} />}

      <div
        className={styles.sliderContainer}
        ref={containerRef}
      >
        <ul ref={sliderRef}>
          {data.map((item, index) => (
            <li
              className={styles.sliderListItem}
              ref={(element) => slidesRef.current[index] = element}
              key={item.name || item.title}
            >
              <SlideButton item={item} onSlideClick={onSlideClick} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PcGamesCarousel;
