import api from '../../data/enum/api';

const initialState = {
  locId: null,
  lang: '',
  accessories: [],
};

const actions = {
  fetchLocationInfo: async(store, locId) => {
    /*
    Location IDs (locId) for Testing Languages and Accessories (all environments)

    Walmart US en-us e82ef006-0b01-4b24-8509-997c22e75a90
    Best Buy US en-us	9b9f8cbe-a4ff-4dc2-9686-7d11e3edcf25
    ca: 208d3128-6cfc-4058-9e79-991cb77ce857
    da: a4cb50ac-8cbb-4cab-9bae-054e612288e0
    de: bebafa72-57da-432b-a28f-74d94c939ab3
    en-gb: 55c9706b-d341-4f39-9cc2-4675d2167589
    en-us: b6289cbb-58fd-46ae-9ae5-ec03021ae9fe
    es: a3aa7a5e-f781-4797-834f-bf2ec8266d4e
    fi: 813c6538-16fb-4966-82fc-0421f96d7a1d
    fr: 02c3b5af-afc6-413d-9cfa-1644578f580d
    fr-ca: 58a13650-2837-4dc9-ae64-9892ade34407
    ja: 45530f65-5508-4171-9188-e711b3ca321d
    ko: cb398f4c-1168-4a45-905d-0d25cf935dea
    no: 712edf92-551b-49dc-845c-a2b8089e46fd
    nl: 31b3a25c-4792-4e1f-a09e-ffe83312b46f
    sv: a7581209-2598-4978-9e8c-f89a7d1f76f0
    */

    const {REACT_APP_API_URL, REACT_APP_API_KEY} = process.env;
    const response = await fetch(
      `${REACT_APP_API_URL}/${api.LOCATION}/${locId}`,
      {
        method: 'GET',
        headers: {
          AuthToken: REACT_APP_API_KEY,
        },
      })
      .then(response => response.json());

    if(!response.extendeddata) {
      return '';
    }

    const { Accessories, Language } = response.extendeddata.details;
    const accessories = Accessories === '' ? [] : Accessories.replace(/\s+/g, '').split(',');

    store.setState({
      ...store.state,
      locId,
      lang: Language,
      accessories,
    });

    return Language;
  },
};

const location = {
  actions,
  initialState,
};

export default location;
