import touchTheBeat from './touch-the-beat.json';
import vrSquare from './vr-square.json';
import youtubeVr from './youtube-vr.json';
import beatSaber from './beat-saber.json';
import beatArena from './beat-arena.json';
import homestarVr from './homestar-vr.json';
import spaceChannel5 from './space-channel5.json';
import counterFightIchiran from './counter-fight-ichiran.json';
import rezInfinite from './rez-infinite.json';
import danceCentral from './dance-central.json';
import OhShape from './OhShape.json';

const data = {
  touchTheBeat,
  vrSquare,
  youtubeVr,
  beatSaber,
  beatArena,
  homestarVr,
  spaceChannel5,
  counterFightIchiran,
  rezInfinite,
  danceCentral,
  OhShape,
};

export default data;
