import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import styles from './menu-link.module.scss';
import Query from '../../../../data/enum/query';
import useGlobal from '../../../../store';

gsap.registerPlugin(ScrollToPlugin);

const MenuSubLink = ({ item, onClick }) => {
  const { hash } = useLocation();
  const [{ locId }] = useGlobal();
  const params = locId ? `?${Query.LOCATION_ID}=${locId}` : '';
  const link = item.link.replace('[locId]', params);

  const handleClick = (e) => {
    const newHash = item.link.split('#')[1];
    const anchorPoint = document.getElementById(newHash);
    if (anchorPoint) {
      gsap.to(window, { duration: 0.4, scrollTo: anchorPoint });
    }

    onClick(e);
  }

  return (
    <li
      className={`${styles.subNavLink} ${hash && item.link.match(new RegExp(hash.split('?')[0] + '$')) ? styles.isActive : ''}`}
    >
      <Link
        to={link}
        data-label={item.enLabel || item.label}
        onClick={handleClick}
        dangerouslySetInnerHTML={{ __html: item.label }}
      />
    </li>
  );
};

export default MenuSubLink;
