import React from 'react';
import { Link } from 'react-router-dom';
import styles from './primary-button.module.scss';

const PrimaryButton = ({ label, trackLabel, to, onClick, isSmall, isDark, className }) => {
  if (to) {
    return (
      <span className={`${className} ${styles.buttonLink} ${isSmall ? styles.isSmall : ''} ${isDark ? styles.isDark : ''}`}>
        <Link to={to} onClick={onClick} data-track-label={trackLabel}>
          <span className={styles.label}>{label}</span>
        </Link>
      </span>
    );
  }

  return (
    <button
      className={`${className} ${styles.button} ${isSmall ? styles.isSmall : ''} ${isDark ? styles.isDark : ''}`}
      onClick={onClick}
      data-track-label={trackLabel}
    >
      <span className={styles.label}>{label}</span>
    </button>
  );
};

export default PrimaryButton;
