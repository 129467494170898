import React, { useRef, useEffect } from 'react';
import { gsap, Power3 } from 'gsap';
import styles from './split-images.module.scss';
import scrollTransition from '../../../util/transitions/scrollTransition';

const SplitImages = ({ images, imageSplit }) => {
  const wrapperRef = useRef(null);
  const imageRef = useRef([]);

  useEffect(() => {
    let scrollTrigger;
    let scrollTrigger2;
    let scrollTrigger3;

    setTimeout(() => {
      scrollTrigger = scrollTransition(
        wrapperRef.current,
        gsap.fromTo(
          imageRef.current[0],
          { x: '-100%' },
          { x: '0%', duration: 0.8, ease: Power3.easeOut }
        ),
      );

      if (images.length === 3) {
        scrollTrigger2 = scrollTransition(
          wrapperRef.current,
          gsap.fromTo(
            imageRef.current[1],
            { x: '-100%' },
            { x: '0%', duration: 0.8, ease: Power3.easeOut }
          ),
        );
        scrollTrigger3 = scrollTransition(
          wrapperRef.current,
          gsap.fromTo(
            imageRef.current[2],
            { x: '100%' },
            { x: '0%', duration: 0.8, ease: Power3.easeOut }
          ),
        );
      } else {
        scrollTrigger2 = scrollTransition(
          wrapperRef.current,
          gsap.fromTo(
            imageRef.current[1],
            { x: '100%' },
            { x: '0%', duration: 0.8, ease: Power3.easeOut }
          ),
        );
      }
    }, 200);

    return () => {
      if (scrollTrigger) {
        scrollTrigger.kill();
      }
      if (scrollTrigger2) {
        scrollTrigger2.kill();
      }
      if (scrollTrigger3) {
        scrollTrigger3.kill();
      }
    };
  }, [images.length]);

  return (
    <div
      className={`${styles.wrapper} ${imageSplit === 3 ? styles.imageSplit3 : styles.imageSplit2}`}
      ref={wrapperRef}
    >
      {imageSplit === 3 && <span className={styles.divider} />}
      {images.map((image, index) =>
        <img
          src={image.url}
          alt={image.alt}
          key={`image-${index}`}
          ref={(element) => imageRef.current[index] = element}
        />)}
    </div>
  );
};

export default SplitImages;
