import fiveNights from './five-nights.json';
import inDeath from './in-death.json';
import theRoomVr from './the-room-vr.json';
import downTheRabbitHole from './down-the-rabbit-hole.json';
import wraith from './wraith.json';
import blairWitch from './blair-witch.json';

const data = {
  fiveNights,
  inDeath,
  theRoomVr,
  downTheRabbitHole,
  wraith,
  blairWitch,
};

export default data;
