import React from 'react';
import { ReactSVG } from "react-svg";
import styles from './icon.module.scss';

const Icon = ({ name, className }) => {
  const icon = require(`../../../asset/icon/${name}.svg`).default;

  return (
    <span className={`${styles.icon} icon ${className}`}>
      <ReactSVG src={icon} />
    </span>
  )
};

export default Icon;
