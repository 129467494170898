import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const scrollTransition = (
  trigger,
  animation,
  props,
) =>
ScrollTrigger.create({
  trigger,
  animation,
  toggleActions: 'play complete none none',
  start: 'top 75%',
  ...props,
});

export default scrollTransition;
