import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ModalContext, { modalNames } from '../../../../context/modal/ModalContext';
import { toCamel } from '../../../../util/stringUtils';
import useGlobal from '../../../../store';
import styles from './game-list-item.module.scss';
import SecondaryButton from '../../../general/SecondaryButton';

const GameListItem = ({ id, gameTranslationName }) => {
  const modal = useContext(ModalContext);
  const { logAction } = useGlobal()[1];
  const { t } = useTranslation(gameTranslationName);
  const data = t(`games.${toCamel(id)}`, { returnObjects: true });
  const enData = t(`games.${toCamel(id)}`, { returnObjects: true, lng: 'en-us' });
  // Game title in English for tracking
  const enTitle = data.enTitle || enData.title || data.title;

  const handleClick = () => {
    // Open game detail modal
    logAction({
      controlName: enTitle,
      context: 'Page',
    });
    modal.showModal(modalNames.GameDetailModal, { ...data, enTitle });
  };

  return (
    <li className={styles.item}>
      <button
        className={styles.button}
        onClick={handleClick}
      >
        <figure className={styles.figure}>
          <img src={data.gridImage || (data.image && data.image.url) || data.carousel[0].image.url} alt={data.title} />
        </figure>
        <span className={styles.label}>{data.title}</span>

        <SecondaryButton label={t('gameButtonLabel')} />
      </button>
    </li>
  );
};

export default GameListItem;
