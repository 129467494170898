import climb2 from './climb2.json';
import scramble from './scramble.json';
import vrFishing from './vr-fishing.json';
import topgolf from './topgolf.json';
import fitXR from './fitXR.json';
import thrillOfFight from './thrill-of-fight.json';

const data = {
  climb2,
  scramble,
  vrFishing,
  topgolf,
  fitXR,
  thrillOfFight,
};

export default data;
