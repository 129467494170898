import Cookie from 'js-cookie';
import cookie from '../../data/enum/cookie';
import api from "../../data/enum/api";
import { eventDescriptions, eventIds } from "../../data/enum/event";
import { getSessionId } from "../../util/getSessionId";
import { getEventTime, getEventTimeTZFormat } from "../../util/getEventTime";

const { REACT_APP_API_URL, REACT_APP_API_KEY, REACT_APP_VERSION } = process.env;

const config = {
  programCode: "OculusTouchScreen",
  clientProgram: "OculusTouchScreen",
  sessionId: getSessionId(),
};

const eventCache = {
  pageUrl: "",
  pageName: "",
  prevPageName: "",
};

const initialState = {
  eventQue: [],
  // trackingEnabled: Cookie.get(cookie.TRACKING) === '1',
  trackingEnabled: true,
  trackingDeclined: Cookie.get(cookie.TRACKING) === '0',
};

const actions = {
  // Disable tracking and empty event que
  declineTracking: (store) => {
    store.setState({
      ...store.state,
      trackingEnabled: false,
      trackingDeclined: true,
      eventQue: [],
    });
  },
  // Enable tracking and fire all qued tracking objects
  enableTracking: (store) => {
    store.setState({
      ...store.state,
      trackingEnabled: true,
      trackingDeclined: false,
    });

    // If there are events in the que while tracking was disabled fire all tracking events
    if (store.state.eventQue.length > 0) {
      store.state.eventQue.forEach((data, index) => {
        fetch(`${REACT_APP_API_URL}/${api.LOG_EVENT}`, {
          method: "POST",
          headers: {
            AuthToken: REACT_APP_API_KEY,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (index + 1 === store.state.eventQue.length) {
          // Emtpy eventQue
          store.setState({
            ...store.state,
            trackingEnabled: true,
            trackingDeclined: false,
            eventQue: [],
          });
        }
      });
    }
  },
  /**
   * @param store
   * @param {string} eventId
   * @param {Object} eventData
   * @param {string|null} locId
   * @param {string} origin
   */
  logEvent: (store, eventId, eventData, locId = null, origin, timeOffset = 0) => {
    const { state } = store;
    const { trackingEnabled, trackingDeclined } = state;
    const data = {
      eventId,
      dateTime: getEventTime(timeOffset),
      eventTime: getEventTime(timeOffset),
      eventTimeTZFormat: getEventTimeTZFormat(timeOffset),
      programCode: config.programCode,
      clientProgram: config.clientProgram,
      // Main Event Node
      eventData: {
        eventId,
        eventDescription: eventDescriptions[eventId],
        sessionGUID: eventId === eventIds.APP_START ? config.sessionId = getSessionId() : config.sessionId,
        version: REACT_APP_VERSION,
        locationId: locId || state.locId,
        deviceType: origin || state.origin,
        pageUrl: eventCache.pageUrl,
        // Event detail
        eventData,
      },
    };

    // console.log('~~', eventId, eventCache.pageUrl, eventData);

    if (trackingEnabled) {
      // If tracking is enabled fire tracking event
      fetch(`${REACT_APP_API_URL}/${api.LOG_EVENT}`, {
        method: "POST",
        headers: {
          AuthToken: REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    } else if (!trackingDeclined) {
      // If tracking is disabled(but not declined) store tracking object in store
      store.setState({
        ...state,
        eventQue: [
          ...state.eventQue,
          data,
        ],
      });
    }
  },
  /**
   * @param actions
   * @param {string} locId
   */
  logAppStart: ({ actions }, locId) => {
    actions.logEvent(eventIds.APP_START, {}, locId);
    actions.logEvent(eventIds.SESSION_START, {}, locId);
  },
  /**
   * @param actions
   * @param {string} locId
   */
  logAppEnd: ({ actions }, locId, timeOffset = 0) => {
    actions.logEvent(eventIds.SESSION_END, {}, locId, timeOffset);
  },
  /**
   * @param actions
   * @param {string} name
   * @param {string} pageUrl
   */
  logPageLoad: ({ actions }, name, pageUrl) => {
    if (pageUrl === eventCache.prevPageName) {
      eventCache.prevPageName = "";
      return;
    }

    actions.updateLogPageUrl(pageUrl);
    actions.logEvent(eventIds.PAGE_LOAD, {
      name,
      type: "page",
    });
  },
  /**
   * @param actions
   * @param {string} name
   * @param {string} path
   */
  logPageScroll: ({ actions }, name, path = "") => {
    actions.updateLogPageUrl(`${eventCache.pageName}/${path}`);
    actions.logEvent(eventIds.PAGE_LOAD, {
      name,
      type: "page",
    });
  },
  /**
   * @param actions
   * @param {Object} eventData
   * @param {string} eventData.name
   * @param {string} eventData.description
   */
  logModalLoad: ({ actions }, eventData) => {
    actions.logEvent(eventIds.MODAL_LOAD, {
      type: "slideover",
      ...eventData,
    });
  },
  /**
   * @param actions
   * @param {Object} eventData
   * @param {string} eventData.controlName
   * @param {string} eventData.context
   * @param {string} eventData.type - click|swipe
   */
  logAction: ({ actions }, eventData) => {
    actions.logEvent(eventIds.ACTION, {
      type: "click",
      ...eventData,
    });
  },
  /**
   * @param actions
   * @param {Object} eventData
   * @param {string} eventData.name
   * @param {string|number} eventData.duration
   * @param {string|number} eventData.played
   */
  logVideoPlay: ({ actions }, eventData) => {
    actions.logEvent(eventIds.VIDEO_PLAYED, {
      ...eventData,
    });
  },
  /**
   * @param {Object} store
   * @param {string} pageUrl
   */
  updateLogPageUrl: (store, pageUrl) => {
    eventCache.pageUrl = pageUrl;
  },
  /**
   * @param {Object} store
   * @param {string} pageName - page from route
   */
  updateLogPageName: (store, pageName) => {
    eventCache.prevPageName = eventCache.pageName;
    eventCache.pageName = pageName;
    eventCache.pageUrl = pageName;
  },
};

const tracking = {
  actions,
  initialState,
};

export default tracking;
