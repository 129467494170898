import climb2 from './climb2.json';
import supernatural from './supernatural.json';
import vrFishing from './vr-fishing.json';
import topgolf from './topgolf.json';
import bowling from './bowling.json';
import scramble from './scramble.json';
import elevenTableTennis from './eleven-table-tennis.json';
import fitXR from './fitXR.json';
import NFL from './NFL-pro-era.json';

const data = {
  climb2,
  scramble,
  supernatural,
  vrFishing,
  topgolf,
  bowling,
  elevenTableTennis,
  fitXR,
  NFL,
};

export default data;
