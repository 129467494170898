import ca from './ca';
import de from './de';
import enGB from './en-gb';
import enUS from './en-us';
import es from './es';
import fr from './fr';
import ja from './ja';
import da from './da';
import fi from './fi';
import frCa from './fr-ca';
import ko from './ko';
import no from './no';
import nl from './nl';
import sv from './sv';

const locale = {
  'ca': ca,
  'de': de,
  'en-gb': enGB,
  'en-us': enUS,
  'es': es,
  'fr': fr,
  'ja': ja,
  'da': da,
  'fi': fi,
  'fr-ca': frCa,
  'ko': ko,
  'no': no,
  'nl': nl,
  'sv': sv,
};

export default locale;

