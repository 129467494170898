import React, { useCallback, useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import eases from '../../../../util/eases';
import Icon from '../../../general/Icon';
import GameListItem from './GameListItem';
import useGlobal from '../../../../store';
import { pageUrls } from '../../../../data/enum/event';
import styles from './game-list.module.scss';
import carousel from '../../../../data/config/carousel';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, Draggable, InertiaPlugin);

const GameList = ({
  data,
  categoryData,
  categoryName,
  selectedCategoryId,
  setSelectedCategoryId,
  scrollToEnabled,
  setScrollToEnabled,
  gameTranslationName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { logPageLoad } = useGlobal()[1];

  const draggerRef = useRef();
  const firstRenderRef = useRef(true);
  const wrapperRef = useRef(null);
  const listContainerRef = useRef(null);
  const sliderContainerRef = useRef(null);
  const sliderRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const isSelected = selectedCategoryId === data.id;
    setIsOpen(isSelected);

    if (isSelected) {
      logPageLoad(categoryName.toUpperCase(), `${pageUrls.APPS_GALLERY}`);
    }
  }, [selectedCategoryId, data.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    draggerRef.current = Draggable.create(sliderRef.current, {
      ...carousel.config,
      bounds: sliderContainerRef.current,
    })[0];

    return () => {
      if(draggerRef.current) {
        gsap.killTweensOf(draggerRef.current);
        draggerRef.current.kill();
        draggerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      gsap.to(wrapperRef.current, {
        duration: 0.5,
        ease: eases.VinnieInOut,
        height: listContainerRef.current.offsetHeight + buttonRef.current.offsetHeight,
      });

      if (scrollToEnabled) {
        gsap.to(window, { duration: 0.4, scrollTo: wrapperRef.current.offsetTop - 60 });
      }
    } else {
      if (firstRenderRef.current) {
        firstRenderRef.current = false;
        gsap.set(wrapperRef.current, {
          height: buttonRef.current.offsetHeight,
        });
      } else {
        if (selectedCategoryId) {
          // collapse after window scroll's positioned
          // to avoid jumpy animation
          gsap.delayedCall(0.5, () => {
            ScrollTrigger.refresh();

            const currentCategoryIndex = categoryData.findIndex(({ id }) => id === data.id);
            const selectedCategoryIndex = categoryData.findIndex(({ id }) => id === selectedCategoryId);

            // Only set page scroll when going to a category down page from current
            if (selectedCategoryIndex > currentCategoryIndex) {
              const height = listContainerRef.current.offsetHeight;
              const scrollTo = window.pageYOffset - height;
              if (window.pageYOffset > height) {
                gsap.set(window, { scrollTo });
              }
            }

            gsap.set(wrapperRef.current, { height: buttonRef.current.offsetHeight });
          });
        } else {
          gsap.to(wrapperRef.current, {
            duration: 0.5,
            ease: eases.VinnieInOut,
            height: buttonRef.current.offsetHeight,
          });
        }
      }
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleButtonClick = useCallback(() => {
    setSelectedCategoryId(isOpen ? '' : data.id);
    setScrollToEnabled(true);
  }, [isOpen, data.id, setSelectedCategoryId, setScrollToEnabled]);

  return (
    <li className={styles.gameList}>
      <div
        className={styles.contentWrapper}
        ref={wrapperRef}
      >
        <button
          className={styles.itemButton}
          onClick={handleButtonClick}
          ref={buttonRef}
        >
          <span className={`${styles.itemButtonLabel} heading-08`}>{data.name}</span>
          <span className={`${styles.itemButtonArrow} ${isOpen ? styles.isOpen : ''}`}>
            <Icon name="small-arrow-left" />
          </span>
        </button>

        <div
          className={styles.listContainer}
          ref={listContainerRef}
        >
          <div
            className={styles.listWrapper}
            ref={sliderContainerRef}
          >
            <ul
              className={styles.list}
              ref={sliderRef}
            >
              {data.games.map(gameId =>
                <GameListItem id={gameId} key={gameId} gameTranslationName={gameTranslationName} />
              )}
            </ul>
          </div>
        </div>
      </div>
    </li>
  );
};

export default GameList;
