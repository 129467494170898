import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MenuLink from './components/MenuLink';
import NavigationContext from '../../../context/navigation/NavigationContext';
import { openNavigation, closeNavigation } from './animation';
import styles from './navigation.module.scss';
import Icon from '../../general/Icon';
import useGlobal from '../../../store';

const Navigation = () => {
  const { isOpen, hideNavigation } = useContext(NavigationContext);
  const location = useLocation();
  const [{ locId, accessories }] = useGlobal();
  const { t } = useTranslation('global');
  const quest2Navigation = t('quest2Navigation', { returnObjects: true });
  const backgroundRef = useRef(null);
  const navRef = useRef(null);
  const itemsRef = useRef({});
  const [openMenuItem, setOpenMenuItem] = useState(-1);

  const close = useCallback(() => {
    hideNavigation();
    setOpenMenuItem(-1);
  }, [hideNavigation, setOpenMenuItem]);

  useEffect(() => {
    close();
  }, [location.pathname, close]);

  useEffect(() => {
    if (isOpen) {
      const itemsRefArr = Object.keys(itemsRef.current).map(key => itemsRef.current[key]);

      openNavigation(navRef.current, backgroundRef.current, itemsRefArr);
    } else {
      closeNavigation(navRef.current, backgroundRef.current);
    }
  }, [isOpen]);

  const handleOpenCloseMenuItem = (index, open) => {
    if (open) {
      setOpenMenuItem(index);
    } else {
      setOpenMenuItem(-1);
    }
  };

  const getQuest2ListItem = (item, index) => {
    return (
      <li
        className={styles.listItem}
        key={item.label}
        ref={(element) => itemsRef.current[index] = element}
      >
        <MenuLink
          item={item}
          onLinkClick={close}
          index={index}
          handleOpenClose={handleOpenCloseMenuItem}
          openMenuItem={openMenuItem}
        />
      </li>
    );
  }

  const renderQuest2ListItem = (item, index) => {
    if (item.id === 'accessories') {
      // If no accessories should be shown hide the menu item
      const hideAccessories = accessories.length === 0 && locId;

      if (!hideAccessories) {
        return getQuest2ListItem(item, index);
      }
    } else {
      return getQuest2ListItem(item, index);
    }
  };

  return (
    <div className={styles.wrapper}>
      <span
        className={styles.background}
        onClick={close}
        ref={backgroundRef}
      />

      <div className={styles.navWrapper} ref={navRef}>
        <button
          className={styles.closeButton}
          onClick={close}
        >
          <Icon name="close" />
        </button>

        <div className={styles.navScrollContainer}>
          <ul className={styles.navigation}>
            {quest2Navigation.map((item, index) => renderQuest2ListItem(item, index))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
