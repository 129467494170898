import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import useGlobal from "../../../store";
import NavigationContext from '../../../context/navigation/NavigationContext';
import Icon from '../../general/Icon';
import Query from '../../../data/enum/query';
import styles from './header.module.scss';

const Header = () => {
  const navigationContext = useContext(NavigationContext);
  const [{ locId }, { logAction }] = useGlobal();
  const location = useLocation();
  const { t } = useTranslation('global');
  const [isLogoVisible, setIsLogoVisible] = useState(true);
  const [backButtonLink, setBackButtonLink] = useState('');

  useEffect(() => {
    let link = '/quest2';

    if (location.pathname === '/quest-pro' || location.pathname === '/quest2') {
      link = '/';
    } else if (location.pathname.includes('quest-pro')) {
      link = '/quest-pro';
    }

    if (locId) {
      link = `${link}?${Query.LOCATION_ID}=${locId}`;
    }

    setBackButtonLink(link);
  }, [location.pathname, locId]);

  useEffect(() => {
    const windowHeight = window.innerHeight;

    const handleScroll = () => {
      if (window.scrollY > (windowHeight * 0.6)) {
        setIsLogoVisible(false);
      } else {
        setIsLogoVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuButtonClick = () => {
    navigationContext.showNavigation();
  };

  const onLogoClick = () => {
    logAction({
      controlName: 'Meta Logo',
      context: 'Nav',
    });
  };

  return (
    <div className={styles.headerSpacer}>
      <div className={styles.header}>
        <div className={styles.wrapper}>
          <span className={styles.headerLogoWrapper}>
            <Link to={backButtonLink} onClick={onLogoClick}>
              <Icon
                name="MetaQuest"
                className={`${styles.headerLogo} ${isLogoVisible ? styles.isVisible : ''}`}
              />
              <Icon
                name="small-arrow-left"
                className={`${styles.backButton} ${isLogoVisible ? '' : styles.isVisible}`}
              />
            </Link>
          </span>

          <button
            className={styles.menuButton}
            onClick={handleMenuButtonClick}
          >
            <span className={styles.menuButtonLabel}>{t('menu')}</span>
            <span className={styles.menuButtonIcon} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
