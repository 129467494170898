import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './config/i18n';
import useGlobal from './store';
import Routes from './Routes';
import HeaderContainer from './components/layout/HeaderContainer';
import ScrollToTop from './components/general/ScrollToTop';
import ModalContextProvider from './context/modal/ModalContextProvider';
import Query from './data/enum/query';
import AppLoader from "./components/block/AppLoader";
import './App.scss';
import './style/main.scss';
// import TrackingOptIn from './components/block/TrackingOptIn';

const App = () => {
  const [{lang}, {fetchLocationInfo, setDeviceType, logAppStart, logAppEnd}] = useGlobal();
  const i18n = useTranslation()[1];
  const param = new URLSearchParams(useLocation().search);
  const locId = param.get(Query.LOCATION_ID);
  const debugLocale = param.get(Query.DEBUG_LOCALE);
  const origin = param.get(Query.ORIGIN) || 'web';

  useEffect(() => {
    let sessionStarted = true;
    let timer = null;

    setDeviceType(origin);
    logAppStart(locId);

    window.onbeforeunload = function() {
      logAppEnd(locId);
    }

    document.addEventListener('visibilitychange', () => {
      if(document.visibilityState === 'hidden') {
        timer = setTimeout(()=>{
          logAppEnd(locId, -30);
          sessionStarted = false;
        }, 30000);
      } else {
        timer && clearTimeout(timer);
        if (!sessionStarted) {
          logAppStart(locId);
          sessionStarted = true;
        }
      }
    });

    if(!locId) {
      return;
    }

    fetchLocationInfo(locId).then((language) => {
      i18n.changeLanguage(language);
      window.document.documentElement.lang = language;
    });

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if((!lang || lang !== i18n.language) && locId) {
    return <AppLoader/>;
  }

  // locale debug only in develop and staging env
  // if locId present, it will be ignored
  const {REACT_APP_DEBUG} = process.env;
  if( REACT_APP_DEBUG && !locId && debugLocale && i18n.language !== debugLocale ) {
    console.log('%c debug locale: ' + debugLocale + " ", 'background: #666; color: #ffff00');
    i18n.changeLanguage(debugLocale);
    window.document.documentElement.lang = debugLocale;
  }

  return (
    <ModalContextProvider>
      <div className="App">
        <ScrollToTop />
        {/*<TrackingOptIn />*/}
        <HeaderContainer />
        <Routes />
      </div>
    </ModalContextProvider>
  );
}


export default App;
