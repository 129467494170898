import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../general/PrimaryButton';
import { gsap } from 'gsap';
import useGlobal from '../../../store';
import Query from '../../../data/enum/query';
import scrollTransition from '../../../util/transitions/scrollTransition';
import { textLinesAnimation } from '../../../util/transitions/animationUtils';
import styles from './explore.module.scss';

const Explore = ({ data, translationName, translationFile }) => {
  const { t } = useTranslation(translationFile);
  const [{ locId }, { logAction }] = useGlobal();
  const params = locId ? `?${Query.LOCATION_ID}=${locId}` : ''
  const wrapperRef = useRef(null);
  const eyebrowRef = useRef(null);
  const titleRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    let scrollTrigger;

    gsap.set([eyebrowRef.current, titleRef.current, buttonRef.current], textLinesAnimation.from);

    setTimeout(() => {
      scrollTrigger = scrollTransition(
        wrapperRef.current,
        gsap.fromTo(
          [eyebrowRef.current, titleRef.current, buttonRef.current],
          textLinesAnimation.from,
          textLinesAnimation.to)
      );

    }, 200);

    return () => {
      if (scrollTrigger) {
        scrollTrigger.kill();
      }
    };
  }, []);

  const onCtaClick = ({ target }) => {
    logAction({
      controlName: target.dataset.trackLabel,
      context: 'Page',
    });
  }

  return (
    <div
      className={styles.wrapper}
      style={{ backgroundImage: `url(${data.background})` }}
      ref={wrapperRef}
    >
      <div className={styles.content}>
        <div>
          <h4 className={`${styles.eyebrow} line-item`} ref={eyebrowRef}>
            {data.label}
          </h4>
          <h3
            className={`${styles.title} heading-04`}
            ref={titleRef}
            dangerouslySetInnerHTML={{__html: data.title}}
          />
        </div>

        <div className={styles.buttonWrapper} ref={buttonRef}>
          <PrimaryButton
            label={data.cta.label}
            trackLabel={t(`${translationName}.cta.label`, {lng: 'en-us'})}
            to={`${data.cta.href}${params}`}
            onClick={onCtaClick}
          />

          {data.disclaimer && <p className={styles.disclaimer}>{data.disclaimer}</p>}
        </div>
      </div>
    </div>
  );
};

export default Explore;
