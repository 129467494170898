import React, { useContext } from 'react';
import ModalContext from '../../../../context/modal/ModalContext';
import Icon from '../../../general/Icon';
import useGlobal from '../../../../store';
import styles from './slide.module.scss';

const Slide = ({ data: { image, video }, title, enTitle }) => {
  const modal = useContext(ModalContext);
  const { logAction } = useGlobal()[1];

  const handlePlayClick = () => {
    modal.showVideoModal({video, enTitle, poster: image.url});
    logAction({
      controlName: 'Launch Media',
      context: enTitle,
    });
  };

  return (
    <figure className={styles.figure}>
      <img src={image.url} alt={image.alt || title} />

      {video && (
        <button className={styles.videoPlayButton} onClick={handlePlayClick}>
          <span className={styles.button}>
            <span className={styles.iconWrapper}>
              <Icon name="play" />
            </span>
          </span>
        </button>
      )}
    </figure>
  );
};

export default Slide;
