import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { Link } from 'react-router-dom';
import useGlobal from '../../../store';
import { textLinesAnimation } from '../../../util/transitions/animationUtils';
import scrollTransition from '../../../util/transitions/scrollTransition';
import scrollTrack from '../../../util/scrollTrack';
import styles from './product-grid.module.scss';
import SecondaryButton from '../../general/SecondaryButton';
import useIsSelected from '../../../util/hooks/useIsSelected';
import Query from '../../../data/enum/query';

gsap.registerPlugin(ScrollToPlugin);

const ProductItem = ({ item }) => (
  <li className={`${styles.listItem} ${styles.item} product`}>
    <div className={styles.itemTopContent}>
      <figure className={styles.image}>
        <img src={item.image} alt={item.label} />
      </figure>
      <span className={styles.label} dangerouslySetInnerHTML={{ __html: item.label }} />
    </div>
  </li>
);

const ProductItemButton = ({ item, buttonLabel }) => {
  const [{ locId }] = useGlobal();
  const params = locId ? `?${Query.LOCATION_ID}=${locId}` : '';
  const link = item.link.replace('[locId]', params);
  const hash = item.link.split('#')[1];

  const handleClick = () => {
    const anchorPoint = document.getElementById(hash);
    if (anchorPoint) {
      gsap.to(window, { duration: 0.4, scrollTo: anchorPoint });
    }
  };

  return (
    <li className={styles.listItem}>
      <Link to={link} className={`${styles.item} product`} onClick={handleClick}>
        <div className={styles.itemTopContent}>
          <figure className={styles.image}>
            <img src={item.image} alt={item.label} />
          </figure>
          <span className={styles.smallLabel} dangerouslySetInnerHTML={{ __html: item.label }} />
        </div>

        <SecondaryButton label={buttonLabel} className={styles.button} />
      </Link>
    </li>
  );
};

const ProductGrid = ({ id, items, title, buttonLabel, isSelected, marquee, trackName, trackPath }) => {
  const { logPageScroll } = useGlobal()[1];

  const wrapperRef = useRef(null);
  const titleRef = useRef(null);
  const productListRef = useRef(null);
  const marqueeWrapperRef = useRef(null);

  useIsSelected(isSelected, wrapperRef.current);

  useEffect(() => {
    let scrollTrigger;
    let scrollTriggerTrack;

    const elements = [
      titleRef.current,
      marqueeWrapperRef.current,
      ...Array.from(productListRef.current.querySelectorAll('.product')),
    ];

    gsap.set(elements, textLinesAnimation.from);

    setTimeout(() => {
      scrollTrigger = scrollTransition(
        wrapperRef.current,
        gsap.fromTo(
          elements,
          textLinesAnimation.from,
          textLinesAnimation.to)
      );

      if (trackPath) {
        scrollTriggerTrack = scrollTrack(wrapperRef.current, () => {
          logPageScroll(trackName || trackPath.toUpperCase(), trackPath);
        });
      }
    }, 200);

    return () => {
      if (scrollTrigger) {
        scrollTrigger.kill();
      }
      if (scrollTriggerTrack) {
        scrollTriggerTrack.kill();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      <span id={id} className={styles.anchorPoint} />
      <div className={styles.titleWrapper}>
        <h3 className={styles.title} ref={titleRef} dangerouslySetInnerHTML={{ __html: title }} />
        <div className={styles.marqueeWrapper} ref={marqueeWrapperRef}>
          {marquee && (
            <>
              <span className={styles.marquee}>{marquee}</span>
              <span className={styles.marquee}>{marquee}</span>
            </>
          )}
        </div>
      </div>
      <ul className={styles.list} ref={productListRef}>
        {items.map((item, index) =>
          buttonLabel ?
            <ProductItemButton item={item} key={`whats-included-${index}`} buttonLabel={buttonLabel} /> :
            <ProductItem item={item} key={`whats-included-${index}`} />
        )}
      </ul>
    </div>
  );
};

export default ProductGrid;
