import React from 'react';
import Icon from '../../general/Icon';
import styles from './app-loader.module.scss';

const AppLoader = () => <div className={styles.appLoader}>
  <div className={styles.logo}>
    <Icon name="MetaQuest" />
  </div>
</div>;

export default AppLoader;
