import React, { useContext, useEffect, useRef } from 'react';
import SecondaryButton from '../SecondaryButton';
import ModalContext, { modalNames } from '../../../context/modal/ModalContext';
import styles from './content.module.scss';
import { gsap } from 'gsap';
import scrollTransition from '../../../util/transitions/scrollTransition';
import { textLinesAnimation } from '../../../util/transitions/animationUtils';
import useGlobal from '../../../store';

const Content = ({ button, copy, eyebrow, footerNote, title, modalData }) => {
  const modal = useContext(ModalContext);
  const { logAction } = useGlobal()[1];

  const wrapperRef = useRef(null);
  const eyebrowRef = useRef(null);
  const titleRef = useRef(null);
  const copyRef = useRef(null);
  const footerNoteRef = useRef(null);
  const buttonRef = useRef(null);

  const handleDetailButtonClick = () => {
    modal.showModal(modalNames.AccessoryDetailModal, modalData);
    logAction({
      controlName: 'VIEW SPECS',
      context: 'Page',
    });
  };

  useEffect(() => {
    let scrollTrigger;

    gsap.set(
      [eyebrowRef.current, titleRef.current, copyRef.current, footerNoteRef.current, buttonRef.current],
      textLinesAnimation.from
    );

    setTimeout(() => {
      if (!wrapperRef.current) {
        return;
      }

      scrollTrigger = scrollTransition(
        wrapperRef.current,
        gsap.fromTo(
          [eyebrowRef.current, titleRef.current, copyRef.current, footerNoteRef.current, buttonRef.current],
          textLinesAnimation.from,
          textLinesAnimation.to),
        {
          start: 'top 95%',
        }
      );
    }, 200);

    return () => {
      if (scrollTrigger) {
        scrollTrigger.kill();
      }
    };
  }, []);

  return (
    <div
      className={styles.wrapper}
      ref={wrapperRef}
    >
      <div className={styles.eyebrowWrapper} ref={eyebrowRef}>
        {eyebrow && <h4 className={styles.eyebrow}>{eyebrow}</h4>}
      </div>
      <h3 className={styles.title} ref={titleRef} dangerouslySetInnerHTML={{ __html: title }} />
      <p className={styles.copy} ref={copyRef} dangerouslySetInnerHTML={{ __html: copy }} />

      {footerNote && <span className={styles.footerNote} ref={footerNoteRef}>{footerNote}</span>}

      {button && (
        <div className={styles.buttonWrapper} ref={buttonRef}>
          <SecondaryButton
            label={button.label}
            onClick={handleDetailButtonClick}
          />
        </div>
      )}
    </div>
  );
};

export default Content;
