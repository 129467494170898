import residentEvil4 from './resident-evil-4.json';
import population from './population.json';
import starWars from './star-wars.json';
import echoVr from './echo-vr.json';
import myst from './myst.json';
import roboRecall from './robo-recall.json';
import moss from './moss.json';
import failFactory from './fail-factory.json';
import youtubeVR from './youtube-vr.json';
import medalOfHonor from './medal-of-honor.json';
import swarm from './swarm.json';
import onward from './onward.json';
import waltzOfTheWizard from './waltz-of-the-wizard.json';
import redMatter from './red-matter.json';
import amongUs from './among-us.json';
import theWalkingDead from './the-walking-dead.json';

const data = {
  residentEvil4,
  population,
  starWars,
  echoVr,
  myst,
  roboRecall,
  moss,
  failFactory,
  youtubeVR,
  medalOfHonor,
  swarm,
  onward,
  waltzOfTheWizard,
  redMatter,
  amongUs,
  theWalkingDead,
};

export default data;
