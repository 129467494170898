import React from 'react';
import globalHook from 'use-global-hook';
import location from './module/location';
import tracking from './module/tracking';
import deviceType from './module/deviceType';

const initialState = {
  ...location.initialState,
  ...tracking.initialState,
  ...deviceType.initialState,
};

const actions = {
  ...location.actions,
  ...tracking.actions,
  ...deviceType.actions,
};

const useGlobal = globalHook(React, initialState, actions);

export default useGlobal;
