import starWars from './star-wars.json';
import echoVr from './echo-vr.json';
import myst from './myst.json';
import roboRecall from './robo-recall.json';
import moss from './moss.json';
import failFactory from './fail-factory.json';
import redMatter from './red-matter.json';
import swarm from './swarm.json';
import waltzOfTheWizard from './waltz-of-the-wizard.json';
import youtubeVR from './youtube-vr.json';
import amongUs from './among-us.json';
import theWalkingDead from './the-walking-dead.json';
import onward from './onward.json';

const data = {
  starWars,
  echoVr,
  myst,
  roboRecall,
  moss,
  failFactory,
  redMatter,
  swarm,
  waltzOfTheWizard,
  youtubeVR,
  amongUs,
  theWalkingDead,
  onward,
};

export default data;
