import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const scrollTrack = (
  trigger,
  logPageScroll,
  props,
) =>
ScrollTrigger.create({
  trigger,
  start: 'top 50%',
  end: 'bottom 50%',
  onEnter: logPageScroll,
  onEnterBack: logPageScroll,
  ...props,
});

export default scrollTrack;
