import React, { useEffect, useRef, useState, useContext } from 'react';
import ImageCarousel from '../ImageCarousel';
import Content from '../../general/Content';
import Icon from '../../general/Icon';
import { gsap, Power3 } from 'gsap';
import scrollTransition from '../../../util/transitions/scrollTransition';
import { textLinesShortAnimation } from '../../../util/transitions/animationUtils';
import useGlobal from '../../../store';
import scrollTrack from '../../../util/scrollTrack';
import styles from './detail-block.module.scss';
import ModalContext from '../../../context/modal/ModalContext';
import { eventActionNames } from '../../../data/enum/event';
import useIsSelected from '../../../util/hooks/useIsSelected';
import DetailBlockSmallImage from '../../general/DetailBlockSmallImage';
import SplitImages from '../../general/SplitImages';

export const aspectRatioTypes = {
  default: styles.aspectRatioDefault,
  large: styles.aspectRatioLarge,
};

export const animationDirection = {
  left: 'left',
  right: 'right',
};

const DetailBlock = ({
  data: { id, eyebrow, title, copy, button, imageSplit, image, images, detailImage, smallImage, imageCarousel, modalData, hasBorderBottom, footerNote },
  trackName,
  trackPath,
  trackScrollOptions,
  contentOffsetTop,
  aspectRatio,
  imageCarouselAspectRatio,
  imageAnimationDirection,
  isSelected,
}) => {
  const [showDetailImage, setShowDetailImage] = useState(false);
  const { logAction, logPageScroll } = useGlobal()[1];
  const modal = useContext(ModalContext);

  const wrapperRef = useRef(null);
  const imageRef = useRef(null);
  const buttonRef = useRef(null);
  const scrollTriggerTrackRef = useRef(null);

  useIsSelected(isSelected, wrapperRef.current);

  useEffect(() => {
    let scrollTrigger;
    let scrollTriggerTrack;

    const direction = imageAnimationDirection === animationDirection.left ? 1 : -1;
    const imageStartX = `${direction * 100}%`;
    if (imageRef.current) {
      gsap.set(imageRef.current, { x: imageStartX });
    }

    if (buttonRef.current) {
      gsap.set(buttonRef.current, textLinesShortAnimation.from);
    }

    setTimeout(() => {
      if (!wrapperRef.current) {
        return;
      }

      scrollTriggerTrack = scrollTrack(wrapperRef.current, () => {
        logPageScroll(trackName || trackPath.toUpperCase(), trackPath);
      }, trackScrollOptions);

      scrollTriggerTrackRef.current = scrollTriggerTrack;

      if (!imageRef.current) {
        return;
      }

      scrollTrigger = scrollTransition(
        wrapperRef.current,
        gsap.fromTo(
          imageRef.current,
          {
            x: imageStartX
          },
          {
            x: '0%',
            duration: 0.8,
            ease: Power3.easeOut,
            onStart: () => {
              if (images) {
                Array.from(imageRef.current.querySelectorAll('img')).map((img, index) => {
                  if (index > 0) {
                    return gsap.fromTo(
                      img,
                      {
                        x: `${direction * index * 25}%`,
                      },
                      {
                        x: '0%',
                        duration: 0.8,
                        ease: Power3.easeOut,
                      })
                  }
                  return null;
                });
              }
            },
            onComplete: () => {
              if (buttonRef.current) {
                gsap.fromTo(buttonRef.current, textLinesShortAnimation.from, textLinesShortAnimation.to);
              }
            },
          }),
      );

    }, 200);

    return () => {
      if (scrollTrigger) {
        scrollTrigger.kill();
      }
      if (scrollTriggerTrack) {
        scrollTriggerTrack.kill();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!scrollTriggerTrackRef.current) {
      return;
    }
    if (modal.state.isOpen) {
      scrollTriggerTrackRef.current.disable();
    } else {
      scrollTriggerTrackRef.current.enable();
    }
  }, [modal.state.isOpen]);

  const handleDetailButtonClick = () => {
    setShowDetailImage(!showDetailImage);
    logAction({
      controlName: !showDetailImage ? eventActionNames.OPEN : eventActionNames.CLOSE,
      context: 'Page',
    });
  };

  return (
    <div
      className={`${styles.wrapper} ${hasBorderBottom ? styles.hasBorderBottom : ''}`}
      ref={wrapperRef}
    >
      <span id={id} className={styles.anchorPoint} />
      {((image || images) && !imageSplit) && (
        <figure className={`${styles.figure} ${aspectRatio}`} ref={imageRef}>
          {image &&
            <img
              src={image.url}
              alt={image.alt || title}
              style={{ display: showDetailImage ? 'none' : 'block' }}
            />
          }

          {images && images.map((image, index) =>
            <img
              src={image.url}
              alt={image.alt || title}
              style={{ display: showDetailImage ? 'none' : 'block' }}
              key={`image-${index}`}
            />)
          }

          {detailImage && (
            <>
              <img
                src={detailImage.url}
                alt={detailImage.alt || title}
                style={{ display: showDetailImage ? 'block' : 'none' }}
              />
              <button
                className={styles.detailButton}
                onClick={handleDetailButtonClick}
                ref={buttonRef}
              >
                <span className={styles.icon}>
                  <span style={{ display: showDetailImage ? 'none' : 'block' }}>
                    <Icon name="plus" />
                  </span>
                  <span className={styles.minusIcon} style={{ display: showDetailImage ? 'block' : 'none' }}>
                    <Icon name="minus" />
                  </span>
                </span>
              </button>
            </>
          )}
        </figure>
      )}
      {imageSplit && images && <SplitImages images={images} imageSplit={imageSplit} />}

      {imageCarousel && (
        <ImageCarousel data={imageCarousel} trackPath={trackPath} aspectRatio={imageCarouselAspectRatio} />
      )}

      <div className={styles.contentWrapper} style={{ marginTop: contentOffsetTop ? contentOffsetTop : '' }}>
        <Content
          button={button}
          copy={copy}
          modalData={modalData}
          eyebrow={eyebrow}
          title={title}
          footerNote={footerNote}
        />

        {smallImage && <DetailBlockSmallImage data={smallImage} />}
      </div>
    </div>
  );
};

export default DetailBlock;
