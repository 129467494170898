import React from 'react';
import styles from './slide.module.scss';
import { imageCarouselAspectRatioType } from '../ImageCarousel';

const Slide = ({ data, aspectRatio, onSlideClick }) => {
  const handleClick = () => {
    onSlideClick(data);
  };

  const aspectRatioClassName = () => {
    if (aspectRatio === imageCarouselAspectRatioType.rectangle) {
      return styles.hasAspectRatioRectangle;
    } else if (aspectRatio === imageCarouselAspectRatioType.square) {
      return styles.hasAspectRatioSquare;
    }
    return '';
  };

  if (onSlideClick) {
    return (
      <button
        className={`${styles.slide} ${aspectRatioClassName()}`}
        onClick={handleClick}
      >
        <figure className={styles.figure}>
          <img src={data.image.url} alt={data.image.alt || data.name || data.title} />
        </figure>
        {data.title && <h4 className={`${styles.title} heading-05`}>{data.title}</h4>}
      </button>
    );
  }

  return (
    <div className={`${styles.slide} ${aspectRatioClassName()}`}>
      <figure className={styles.figure}>
        <img src={data.image.url} alt={data.image.alt || data.name || data.title} />
      </figure>
      {data.title && <h4 className={`${styles.title} heading-05`}>{data.title}</h4>}
    </div>
  );
};

export default Slide;
