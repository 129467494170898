import starWars from './star-wars.json';
import echoVr from './echo-vr.json';
import myst from './myst.json';
import roboRecall from './robo-recall.json';
import moss from './moss.json';
import failFactory from './fail-factory.json';
import amongUs from './among-us.json';
import theWalkingDead from './the-walking-dead.json';
import youtubeVR from './youtube-vr.json';

const data = {
  starWars,
  echoVr,
  myst,
  roboRecall,
  moss,
  failFactory,
  amongUs,
  theWalkingDead,
  youtubeVR,
};

export default data;
