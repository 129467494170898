import global from './global.json';
import quest2 from './quest2.json';
import apps from './apps';
import accessories from './accessories.json';

const data = {
  global,
  quest2,
  apps,
  accessories,
};

export default data;
