import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gsap, Linear } from 'gsap';
import Content from '../../general/Content';
import eases from '../../../util/eases';
import { textLinesAnimation } from '../../../util/transitions/animationUtils';
import scrollTransition from '../../../util/transitions/scrollTransition';
import Video from '../../general/Video/Video';
import useGlobal from '../../../store';
import styles from './get-ready.module.scss';
import scrollTrack from '../../../util/scrollTrack';
import useIsSelected from '../../../util/hooks/useIsSelected';

const Button = ({ index, item, onClick }) => {
  const handleClick = useCallback(() => {
    onClick(index);
  }, [index, onClick]);

  return (
    <button
      className={styles.listItemButton}
      onClick={handleClick}
    >
      <span className={styles.listItemNumber}>0{index + 1}</span>
      <span className={styles.listItemTitle} dangerouslySetInnerHTML={{ __html: item }} />
    </button>
  );
};

const GetReady = ({ isSelected }) => {
  const { logAction, logPageScroll } = useGlobal()[1];
  const { t } = useTranslation('quest2');
  const data = t('setup.list', { returnObjects: true });
  const trackList = t('setup.list', { returnObjects: true, lng: 'en-us' }).map(item => item.replace('+', ''));
  const [activeItem, setActiveItem] = useState(0);
  const [inView, setInView] = useState(false);

  const wrapperRef = useRef(null);
  const imageListRef = useRef(null);
  const buttonListRef = useRef(null);
  const activeIndicatorWrapperRef = useRef(null);
  const progressIndicatorsRef = useRef([]);
  const buttonRef = useRef(null);

  const handleClick = useCallback((index) => {
    gsap.killTweensOf(progressIndicatorsRef.current);
    gsap.set(progressIndicatorsRef.current, { width: '0%' });

    setActiveItem(index);
    logAction({
      controlName: trackList[index],
      context: 'Page',
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleVideoTimeUpdate = useCallback((event) => {
    const percent = event.target.currentTime / event.target.duration

    if (percent === 1) {
      if (activeItem < data.length - 1) {
        setActiveItem(activeItem + 1)
      } else {
        setActiveItem(0)
      }
    }
  }, [activeItem, data]);

  useIsSelected(isSelected, wrapperRef.current);

  useEffect(() => {
    if (activeItem === data.length - 1) {
      const maxLeftScroll = (buttonRef.current.offsetWidth * data.length) - buttonListRef.current.offsetWidth + 60
      gsap.to(buttonListRef.current, { scrollLeft: maxLeftScroll, duration: .7, ease: eases.VinnieInOut })
    } else {
      gsap.to(buttonListRef.current, { scrollLeft: 0, duration: .7, ease: eases.VinnieInOut })
    }
  }, [activeItem, data]);

  useEffect(() => {
    let scrollTrigger;
    let scrollTriggerTrack;

    gsap.set(
      [imageListRef.current, buttonListRef.current, activeIndicatorWrapperRef.current],
      textLinesAnimation.from
    );

    setTimeout(() => {
      scrollTrigger = scrollTransition(
        wrapperRef.current,
        gsap.fromTo(
          [imageListRef.current, buttonListRef.current, activeIndicatorWrapperRef.current],
          textLinesAnimation.from,
          textLinesAnimation.to),
        {
          onToggle: self => setInView(self.isActive),
        }
      );

      scrollTriggerTrack = scrollTrack(wrapperRef.current, () => {
        logPageScroll('EASY SETUP', 'easy setup');
      });

    }, 200);

    return () => {
      if (scrollTrigger) {
        scrollTrigger.kill();
      }
      if (scrollTriggerTrack) {
        scrollTriggerTrack.kill();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePlayVideo = (e) => {
    const videoLength = e.currentTarget.duration;

    if (activeItem === 0) {
      gsap.killTweensOf(progressIndicatorsRef.current);
      gsap.set(progressIndicatorsRef.current, { width: '0%' });
    }

    gsap.fromTo(progressIndicatorsRef.current[activeItem], {
      width: '0%',
    }, {
      width: '100%',
      duration: videoLength,
      ease: Linear.easeNone,
    });
  };

  return (
    <div ref={wrapperRef}>
      <span id={data.id} className={styles.anchorPoint} />
      <ul className={styles.setupList}
        ref={imageListRef}
        style={{ backgroundImage: 'url(/asset/video/quest-2-setup-bg.jpg)' }}>
        {data.map((item, index) => (
          <li
            className={`${styles.setupItem} ${activeItem === index ? styles.isActive : ''}`}
            key={`setup-${index}`}
          >
            <Video mp4={`/asset/video/quest-2-setup-0${index + 1}`}
              poster={`/asset/video/quest-2-setup-0${index + 1}.jpg`}
              isActive={inView && activeItem === index}
              timeUpdateCallback={handleVideoTimeUpdate}
              onPlay={handlePlayVideo}
              loop={false}
            />
          </li>
        ))}
      </ul>

      <div className={styles.listContainer} ref={activeIndicatorWrapperRef}>
        <div className={styles.listWrapper} ref={buttonListRef}>
          <ul className={styles.list}>
            {data.map((item, index) => (
              <li
                className={`${styles.listItem} ${activeItem === index ? styles.isActive : ''}`}
                key={`button-${index}`}
                ref={buttonRef}
              >
                <Button
                  index={index}
                  item={item}
                  onClick={handleClick}
                />
                <span className={styles.itemProgressWrapper}>
                  <span
                    className={styles.itemProgress}
                    ref={el => progressIndicatorsRef.current[index] = el}
                  />
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className={styles.contentWrapper}>
        <Content
          eyebrow={t('setup.eyebrow')}
          title={t('setup.title')}
          copy={t('setup.description')}
        />
      </div>
    </div>
  );
};

export default GetReady;
