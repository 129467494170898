import React, { useState, useEffect } from 'react';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import styles from './back-to-top-button.module.scss';
import Icon from '../Icon';

gsap.registerPlugin(ScrollToPlugin);

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const windowHeight = window.innerHeight;

    const handleScroll = () => {
      if (window.scrollY > (windowHeight * 0.6)) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => {
    gsap.to(window, { duration: 0.4, scrollTo: 0 });
  };

  return (
    <button
      className={`${styles.button} ${isVisible ? styles.isVisible : ''}`}
      onClick={handleClick}
    >
      <Icon name="small-arrow-left" />
    </button>
  );
};

export default BackToTopButton;
