import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import styles from './Video.module.scss';

const Video = ({
  mp4,
  poster,
  className = '',
  loop = true,
  isActive = false,
  timeUpdateCallback = null,
  onPlay = null,
}) => {
  const videoEl = useRef(null);

  useEffect(() => {
    if (!videoEl.current) return;

    if (isActive) {
      videoEl.current.currentTime = 0;
      const playPromise = videoEl.current.play();

      if (playPromise !== undefined) {
        playPromise.catch(error => {
          console.log(error);
        });
      }
    } else {
      videoEl.current.pause();
      gsap.delayedCall(.4, () => {
        if (!isActive) {
          videoEl.current.currentTime = 0;
        }
      })
    }
  }, [isActive]);

  return (
    <div className={`${className} ${styles.video}`}>
      <video
        ref={videoEl}
        preload="true"
        muted
        loop={loop}
        playsInline
        poster={poster}
        onTimeUpdate={timeUpdateCallback}
        onPlay={onPlay}
      >
        <source src={`${mp4}.mp4`} type="video/mp4" />
      </video>
    </div>
  );
};
export default Video;
