import { Power3 } from "gsap";

export const textLinesAnimation = {
  from: {
    y: 40,
    autoAlpha: 0,
  },
  to: {
    y: 0,
    autoAlpha: 1,
    duration: 0.8,
    ease: Power3.easeOut,
    stagger: 0.15,
  },
};

export const textLinesShortAnimation = {
  from: {
    y: 15,
    autoAlpha: 0,
  },
  to: {
    y: 0,
    autoAlpha: 1,
    duration: 0.8,
    ease: Power3.easeOut,
    stagger: 0.15,
  },
};
