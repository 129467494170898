import tetrisEffect from './tetris-effect.json';
import jobSimulator from './job-simulator.json';
import cookOut from './cook-out.json';
import angryBirdsVr from './angry-birds-vr.json';

const data = {
  tetrisEffect,
  jobSimulator,
  cookOut,
  angryBirdsVr,
};

export default data;
