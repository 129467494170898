import tetrisEffect from './tetris-effect.json';
import jobSimulator from './job-simulator.json';
import cookOut from './cook-out.json';
import angryBirdsVr from './angry-birds-vr.json';
import demeo from './demeo.json';
import starWarsPinballVR from './star-wars-pinball-vr.json';

const data = {
  tetrisEffect,
  jobSimulator,
  cookOut,
  angryBirdsVr,
  demeo,
  starWarsPinballVR,
};

export default data;
